

@font-face {
  font-family: "Flaticon2";
  src: url("./Flaticon2.eot");
  src: url("./Flaticon2.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon2.woff2") format("woff2"),
       url("./Flaticon2.woff") format("woff"),
       url("./Flaticon2.ttf") format("truetype"),
       url("./Flaticon2.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon2";
    src: url("./Flaticon2.svg#Flaticon") format("svg");
  }
}

[class^="flaticon2-"]:before, [class*=" flaticon2-"]:before,
[class^="flaticon2-"]:after, [class*=" flaticon2-"]:after {   
  font-family: Flaticon2;
  font-size: 33px;
  font-style: normal;
}

.flaticon2-box:before { content: "\f100"; }
.flaticon2-hand:before { content: "\f101"; }
.flaticon2-contract:before { content: "\f102"; }
.flaticon2-template:before { content: "\f103"; }
.flaticon2-calendar:before { content: "\f104"; }
.flaticon2-calendar-1:before { content: "\f105"; }
.flaticon2-deadline:before { content: "\f106"; }