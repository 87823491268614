
@font-face {
  font-family: "Flaticon1";
  src: url("./Flaticon1.eot");
  src: url("./Flaticon1.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon1.woff2") format("woff2"),
       url("./Flaticon1.woff") format("woff"),
       url("./Flaticon1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[class^="flaticon1-"]:before, [class*=" flaticon1-"]:before,
[class^="flaticon1-"]:after, [class*=" flaticon1-"]:after {   
  font-family: Flaticon1;
  font-size: initial;
  font-style: normal;
  line-height: normal;
}

.flaticon1-signature:before { content: "\f100"; }
.flaticon1-check-square:before { content: "\f101"; }
.flaticon1-calendar:before { content: "\f102"; }
.flaticon1-text:before { content: "\f103"; }
.flaticon1-writer:before { content: "\f104"; }