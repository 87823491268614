@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
@import url(https://fonts.googleapis.com/css?family=Love+Ya+Like+A+Sister);
body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, 'Nunito Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	letter-spacing: .03rem
}


.driveManagment{
	display: flex;
    align-items: center;
}
.driveManagment div+div {
    margin-left: 10px;
}

.inlineLoaderGif {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	right: 0
}

.inlineLoaderGif img {
	height: auto
}

.inlineLoaderGif img {
	height: 90px
}

::-webkit-scrollbar {
	width: 6px
}

::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, .1);
	border-radius: 10px
}

::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
	border-radius: 10px
}

.ReactMainContainer {
	height: 100%
}

.disableAddingNewRole {
	background: #bbc8d8!important;
	cursor: default!important
}

button.btn.theme_btn[disabled]:hover:before {
	background: #768da9;
	-webkit-transform: scale(1);
	    -ms-transform: scale(1);
	        transform: scale(1)
}

button.btn.theme_btn[disabled]:hover {
	color: #fff!important;
	opacity: 1
}

.singlePageFullHeight {
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none
}

.singlePageFullHeight img {
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none
}

a,
a:active,
a:hover {
	text-decoration: none!important
}

ul.list-unstyled li {
	display: inline-block;
	padding: 10px
}

html,body {
	min-height: 100vh;
}

#root {
	min-height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace
}

.navbar-right-area {
	margin-left: auto
}

.navbar-right-area img {
	width: 50px;
	border-radius: 50%
}

.navbar-expand-lg {
	-webkit-box-pack: justify!important;
	    -ms-flex-pack: justify!important;
	        justify-content: space-between!important
}

.metismenu li {
	list-style: none
}

.metismenu-item ul.metismenu-container {
	opacity: 0;
	height: 0;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	overflow: hidden;
	background: #385f8f
}

.metismenu-item ul.metismenu-container.visible {
	opacity: 1;
	height: auto;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s
}

.metismenu-item ul.metismenu-container.visible>li>button {
	padding-left: 35px
}

.metismenu-item ul.metismenu-container.visible>li:hover {
	background: #385f8f
}

.metismenu-item a.metismenu-link i.metismenu-state-icon.rotate-minus-90 {
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	    transform: rotate(0)
}

.metismenu .metismenu-container {
	padding: 0;
	margin: 0
}

.main-body-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-flow: row;
	    flex-flow: row;
	min-height: 100%;
	height: auto
}

.main-body-content .metismenu {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	background: url(../images/side-bg.png) #2f517d;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top left;
	height: calc(100vh - 99px);
	overflow: auto;
	position: fixed;
	left: 0;
	top: 60px;
	width: 240px;
	padding: 20px 0 0 0
}

.right-body-content {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	margin-left: 240px;
	width: calc(100% - 240px);
	padding: 70px 5px 50px 5px;
	/* background: #fafafa; */
	position: relative;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}

.metismenu-item a.metismenu-link {
	padding: 8px 16px;
	font-size: 14px;
	display: block;
	color: #fff!important;
	text-decoration: none
}

.metismenu-link+.metismenu-container.visible .metismenu-item .metismenu-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center
}

.metismenu-link+.metismenu-container.visible .metismenu-item .metismenu-link i.fa-circle {
	font-size: 8px
}

.metismenu-link+.metismenu-container.visible .metismenu-item .metismenu-link i.fa-circle:before {
	font-size: 8px
}

.metismenu-link+.metismenu-container.visible .metismenu-item .metismenu-link[href="/#/documents-all"] i {
	color: #9e9e9e
}

.metismenu-link+.metismenu-container.visible .metismenu-item .metismenu-link[href="/#/documents-signed"] i {
	color: #00bcd4
}

.metismenu-link+.metismenu-container.visible .metismenu-item .metismenu-link[href="/#/documents-canceled"] i {
	color: #ff5722
}

.metismenu-link+.metismenu-container.visible .metismenu-item .metismenu-link[href="/#/documents-unsigned"] i {
	color: #ffeb3b
}

.metismenu-link+.metismenu-container.visible .metismenu-item .metismenu-link[href="/#/documents-inprogress"] i {
	color: #8bc34a
}

.metismenu>.metismenu-container>.metismenu-item>a .flaticon2-template:before {
	font-size: 30px
}

.metismenu-item a.metismenu-link i.metismenu-icon {
	margin-right: 15px;
	font-size: 22px;
	line-height: normal
}

.metismenu-item a.metismenu-link i.metismenu-icon.fa-angle-right {
	font-size: 17px;
	margin-right: 10px
}

.metismenu-item a.metismenu-link i.metismenu-icon::before {
	font-size: 22px;
	line-height: 20px
}

.metismenu-container.visible .metismenu-item a.metismenu-link i.metismenu-icon::before {
	font-size: 17px
}

.metismenu-container.visible .metismenu-item a.metismenu-link i.metismenu-icon.fa-angle-right::before {
	font-size: 17px
}

.metismenu-item a.metismenu-link i.metismenu-state-icon {
	margin: auto;
	margin-right: 0;
	font-size: 18px;
	line-height: 10px;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	    transform: rotate(-90deg)
}

.metismenu-item a.metismenu-link i.metismenu-state-icon.fa-caret-left:before {
	content: "\f107"
}

.metismenu-item a.metismenu-link i.metismenu-icon.fa-ti-lock {
	font-family: themify;
	speak: none;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.metismenu-item a.metismenu-link i.metismenu-icon.fa-ti-lock:before {
	content: "\E62B"
}

.metismenu>.metismenu-container>.metismenu-item>a {
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, .05);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	background: 0 0;
	width: 100%
}

.metismenu-item a.metismenu-link.active,
.metismenu-item a.metismenu-link:active,
.metismenu-item a.metismenu-link:focus,
.metismenu-item a.metismenu-link:hover {
	background-color: #44b319;
	outline: 0;
	color: #fff
}

.main-footer {
	position: fixed;
	bottom: 0;
	z-index: 9;
	left: 0;
	width: 100%;
	padding: 10px;
	background: #303030;
	color: #fff;
	text-align: center
}

.main-footer p {
	margin: 0;
	padding: 0;
	font-size: 13px
}

.fullhightflex {
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.has-active-child {
	background-color: #1a3961
}

.metismenu-link.active {
	background-color: #44b319;
	color: #fff!important
}

.clear5 {
	clear: both;
	height: 5px
}

.clear10 {
	clear: both;
	height: 10px
}

.clear15 {
	clear: both;
	height: 15px
}

.clear20 {
	clear: both;
	height: 20px
}

.clear25 {
	clear: both;
	height: 25px
}

.clear30 {
	clear: both;
	height: 30px
}

.searchbar-group {
	max-width: 450px;
	float: right
}

.flex-parent .search-input-group {
	max-width: 40%;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 40%;
	        flex: 0 0 40%
}

.theme_btn {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto
}

.searchbar-group-dropdown {
	position: absolute;
	width: 600px;
	background-color: #fff;
	margin-top: -16px;
	padding: 25px 15px;
	-webkit-box-shadow: 1px 1px 7px 0 #c9c9c9;
	        box-shadow: 1px 1px 7px 0 #c9c9c9;
	border-radius: 4px;
	z-index: 99
}

.searchbar-group-dropdown form div:last-child {
	margin-bottom: 0
}

.margin-right-5 {
	margin-right: 5px
}

.margin-right-10 {
	margin-right: 10px
}

.navbar {
	position: fixed!important;
	width: 100%;
	z-index: 9
}

.react-confirm-alert-button-group>button {
	font-size: 14px!important;
	padding: 4px 25px!important
}

.react-confirm-alert-button-group>button:first-child {
	background: #3e92ed
}

.react-confirm-alert-button-group>button:last-child {
	background: #dc4343
}

.react-confirm-alert-body {
	padding: 20px 20px!important;
	border: 1px solid rgba(237, 237, 237, .85)
}

.react-confirm-alert-body>h1 {
	line-height: normal;
	font-size: 1.8rem
}

.fieldSettingsContainer {
	background: #e8e8e8;
	width: 250px;
	border-radius: 5px;
	padding: 10px 15px;
	/* z-index: 99999999 */
}

.fieldSettingsContainer .pull-right .fa-minus {
	cursor: pointer
}

.fieldSettingsContainer input[type=text] {
	width: 100%;
	padding: 4px 8px;
	border: 1px solid #bdbdbd;
	border-radius: 3px;
	font-size: 14px
}

.fieldSettingsContainer select {
	width: 100%;
	padding: 4px 8px;
	border: 1px solid #bdbdbd;
	border-radius: 3px;
	font-size: 14px
}

.fieldSettingsContainerOpener {
	display: block;
	width: 40px;
	height: 40px;
	background: #e8e8e8;
	-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, .18);
	        box-shadow: 2px 2px 4px rgba(0, 0, 0, .18);
	margin: 15px auto 0 auto;
	position: relative;
	z-index: 99999999
}

.fieldSettingsContainerOpener:before {
	content: "";
	height: 15px;
	width: 15px;
	position: absolute;
	left: 0;
	right: 0;
	top: -7px;
	background: #e8e8e8;
	margin: 0 auto;
	-webkit-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	    transform: rotate(135deg)
}

.fieldSettingsContainer {
	margin: 15px auto 0 auto;
	-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, .18);
	        box-shadow: 2px 2px 4px rgba(0, 0, 0, .18);
	position: relative
}

.img-responsive {
	width: 100%
}

.cursor-pointer {
	cursor: pointer
}

.esignpanda-header {
	background: #f8f9fa;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding: 15px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999
}

.esignpanda-body {
	margin-top: 65px
}

.esignpanda-header-menu {
	margin-right: 15px;
	margin-left: 25px;
	position: relative
}

.esignpanda-header-menu img {
	width: 6px
}

.esignpanda-logo {
	width: 100px
}

.esignpanda-right-container {
	margin-left: auto
}

.esignpanda-right-container button {
	border: 1px solid #334b82;
	background: #334b82;
	color: #fff;
	padding: 3px 15px;
	border-radius: 4px;
	font-size: 14px
}

.modal-body.esignModal-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 0;
	min-height: 340px
}

.templateName-with-id-wrap {
	max-height: 340px;
	overflow: auto
}

.eSign-lef-panel {
	max-width: 30%;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 30%;
	        flex: 0 0 30%;
	background: rgba(236, 236, 236, .45);
	border-right: 1px solid #e4e4e4
}

.eSign-right-panel {
	max-width: 70%;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 70%;
	        flex: 0 0 70%
}

.eSign-lef-panel .draw-it {
	display: inline-block;
	width: 100%;
	padding: 10px;
	background: #efefef;
	border-color: #e4e4e4;
	border-width: 1px 0 1px 0;
	border-style: solid;
	font-size: 14px;
	cursor: pointer
}

.eSign-lef-panel .draw-it:first-child {
	margin-top: 40px
}

.eSign-lef-panel .draw-it a {
	color: #5a5a5a
}

.canceledpercentage,
.signedpercentage,
.unsignedpercentage {
	text-align: center
}

.unsignedpercentage .CircularProgressbar-path {
	stroke: #2283f5!important
}

.unsignedpercentage .CircularProgressbar-trail {
	stroke: #efefef!important
}

.unsignedpercentage .CircularProgressbar-text {
	fill: #2283f5!important
}

.signedpercentage .CircularProgressbar-path {
	stroke: #448820!important
}

.signedpercentage .CircularProgressbar-trail {
	stroke: #efefef!important
}

.signedpercentage .CircularProgressbar-text {
	fill: #448820!important
}

.signedpercentage h3 {
	color: #448820;
	font-size: 18px;
	font-weight: 700
}

.canceledpercentage .CircularProgressbar-path {
	stroke: #de2e1e!important
}

.canceledpercentage .CircularProgressbar-trail {
	stroke: #efefef!important
}

.canceledpercentage .CircularProgressbar-text {
	fill: #de2e1e!important
}

.canceledpercentage h3 {
	color: #de2e1e;
	font-size: 18px;
	font-weight: 700
}

.canceledpercentage .CircularProgressbar,
.signedpercentage .CircularProgressbar,
.unsignedpercentage .CircularProgressbar {
	width: 40%!important;
	padding: 10px 0
}

.singlePageRepeaterMain {
	position: relative;
	border-bottom: 2px solid #c1bebe
}

.elementsContainerHead {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 10px;
	background: #e2e2e2
}

.elementsContainerHead .elementsContainer {
	margin-right: auto;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex
}

.elementsContainerHead .custom_theme_btn {
	margin-left: auto;
	background: #2c4f7a;
	border: 1px solid #2c4f7a;
	color: #fff;
	padding: 7px 30px;
	border-radius: 4px
}

.elementsContainerHead .dragable_elements {
	margin-right: 5px;
	padding: 0 10px;
	background: #fff;
	color: #2c4f7a;
	border-radius: 2px;
	border: 1px solid #dcdcdc;
	-webkit-box-shadow: 1px 1px 6px rgba(123, 123, 123, .18);
	        box-shadow: 1px 1px 6px rgba(123, 123, 123, .18);
	cursor: move;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center
}

.elementsContainerHead .dragable_elements i {
	margin-right: 10px;
	color: #2c4f7a
}

.textTypeElement {
	background: 0 0;
	-webkit-box-shadow: none;
	        box-shadow: none;
	border: none
}

.textTypeElement:focus {
	outline: 0
}

.textTypeElement:-moz-read-only {
	border: 1px solid green;
	border-top: none;
	line-height: 1
}

.textTypeElement:read-only {
	border: 1px solid green;
	border-top: none;
	line-height: 1
}

.particularElementDropped {
	border: 1px solid grey;
	border-top: none;
	text-align: center;
	line-height: 0
}

.particularElementDropped.esignElement {
	border: 1px solid #d4d4d4!important;
	border-top: none;
	text-align: center;
	height: 30px!important;
	line-height: 0;
	padding: 0 5px;
	-webkit-box-shadow: 0 1px 2px 0;
	        box-shadow: 0 1px 2px 0;
	background: #f5f5f5
}

.particularElementDropped.esignElement:hover {
	background: #d6d6d6;
	cursor: pointer;
}

.particularElementDropped input {
	line-height: normal;
	font-size: 14px;
	font-family: Arial;
	padding: 0 5px
}

.particularElementDropped .esign-hand-writing {
	line-height: normal
}

.particularElementDropped:focus {
	outline: 0
}

.fieldSettingsOpenerIconContainer {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center
}

.esignModal-footer {
	border-top: 1px solid #e4e4e4;
	background: #f6f6f6
}

.esignModal-footer button.btn-primary {
	border: 1px solid #334b82;
	background: #334b82;
	color: #fff;
	padding: 5px 20px;
	border-radius: 4px
}

.esignModal-footer button.btn-secondary {
	background: #4a6fc3;
	border-color: #4a6fc3
}

#signature-pad {
	margin: 0 30px;
	overflow: hidden
}

.esignModal-head span {
	width: 100%;
	display: block;
	text-align: center;
	padding: 20px 0;
	position: relative;
	text-transform: uppercase
}

.esignModal-head span+i {
	position: absolute;
	top: 20px;
	right: 15px;
	cursor: pointer
}

.fieldSettingsContainer:before {
	content: "";
	height: 15px;
	width: 15px;
	position: absolute;
	left: 0;
	right: 0;
	top: -7px;
	background: #e8e8e8;
	margin: 0 auto;
	-webkit-transform: rotate(135deg);
	    -ms-transform: rotate(135deg);
	        transform: rotate(135deg)
}

.removeAddedElement {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #e8e8e8;
	color: #000;
	position: absolute;
	bottom: 0;
	right: -25px;
	line-height: 20px;
	text-align: center;
	font-size: 13px;
	cursor: pointer
}

.dashboard-nav-btn .fa {
	font-size: 30px;
	background: #fbfbfb;
	height: 60px;
	width: 60px;
	border-radius: 50px;
	line-height: 60px;
	-webkit-box-shadow: 0 0 10px 2px rgba(66, 66, 66, .3);
	        box-shadow: 0 0 10px 2px rgba(66, 66, 66, .3)
}

.btn-success.dashboard-nav-btn .fa {
	color: #38a845
}

.btn-primary.dashboard-nav-btn .fa {
	color: #2283f5
}

.btn-info.dashboard-nav-btn .fa {
	color: #16a2b8
}

.navbar-expand-lg .navbar-nav .dropdown-menu-right {
	top: 5px!important
}

.navbar-expand-lg .navbar-nav .dropdown-menu-right:after,
.navbar-expand-lg .navbar-nav .dropdown-menu-right:before {
	bottom: 100%;
	left: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none
}

.navbar-expand-lg .navbar-nav .dropdown-menu-right:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: #fff;
	border-width: 10px;
	margin-left: -39px
}

.navbar-expand-lg .navbar-nav .dropdown-menu-right:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: rgba(0, 0, 0, .15);
	border-width: 11px;
	margin-left: -40px
}

.page_title {
	margin-bottom: 20px;
	color: #2c4f7b
}

label.custom-file-upload {
	text-align: left;
	font-size: 16px;
	font-weight: 600;
	margin-right: 10px;
	margin-bottom: 0;
    width: 80px;
	text-transform: capitalize;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 70px;
	        flex: 0 0 70px;
}

.roleNameField_parent {
	position: relative;
	margin-bottom: 10px
}

.roleNameField_parent span {
	position: absolute;
	right: -23px;
	top: 15px;
	display: inline-block;
	height: 17px;
	width: 17px;
	line-height: 17px;
	background: #2c4f7b;
	text-align: center;
	color: #fff;
	border-radius: 50%;
	font-size: 12px;
	cursor: pointer
}

.theme_btn {
	background: #fff!important;
	border: 1px solid #2c4f7a!important;
	color: #fff!important;
	padding: 7px 30px;
	border-radius: 4px;
	position: relative;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-transition-property: color;
	-o-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
	   transition-duration: .3s;
    text-transform: capitalize;
}

.theme_btn:active,
.theme_btn:focus,
.theme_btn:hover {
	color: #2c4f7a!important
}

.theme_btn:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #2c4f7a;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	    transform: scale(1);
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	-o-transition-property: transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: .3s;
	-o-transition-duration: .3s;
	   transition-duration: .3s;
	-webkit-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;
	   transition-timing-function: ease-out
}

.theme_btn:active:before,
.theme_btn:focus:before,
.theme_btn:hover:before {
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	    transform: scale(0)
}

.theme_close_btn {
	background: #fff!important;
	border: 1px solid #f44336!important;
	color: #fff!important;
	padding: 7px 30px;
	border-radius: 4px;
	position: relative;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-transition-property: color;
	-o-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: .3s;
	-o-transition-duration: .3s;
	   transition-duration: .3s
}

.theme_close_btn:active,
.theme_close_btn:focus,
.theme_close_btn:hover {
	color: #f44336!important
}

.theme_close_btn:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #f44336;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	    transform: scale(1);
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	-o-transition-property: transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: .3s;
	-o-transition-duration: .3s;
	   transition-duration: .3s;
	-webkit-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;
	   transition-timing-function: ease-out
}

.theme_close_btn:active:before,
.theme_close_btn:focus:before,
.theme_close_btn:hover:before {
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	    transform: scale(0)
}

.input-group-append .btn-outline-secondary {
	color: #ced4da;
	border-color: #ced4da
}

.inner-box {
	border: 2px solid #e5e5e5;
	padding: 0 15px;
	margin-bottom: 15px;
	border-radius: .25rem
}

.inner-box form input[type=file] {
	cursor: pointer;
	width: 210px;
	height: 34px;
	line-height: 34px;
	overflow: hidden;
	outline: 0
}

.inner-box form {
	line-height: normal;
	height: auto;
	margin-top: 1px
}

.inner-box form input[type=file]:before {
	width: 100%;
	height: 36px;
	font-size: 16px;
	line-height: 34px;
	content: 'Select your file';
	display: inline-block;
	background: #2c4f7b;
	border: 1px solid #2c4f7b;
	color: #fff;
	padding: 0 10px;
	text-align: center;
    border-radius: 4px;
    text-transform: capitalize;
}

.inner-box form input[type=file]::-webkit-file-upload-button {
	visibility: hidden
}

.eSign-right-panel {
	text-align: center;
	padding-bottom: 30px
}

.eSign-right-panel canvas {
	display: inline-block;
	background: #f6f6f6;
	border: 1px solid #eaeaea
}

.canvas-wrapper {
	width: 78%;
	margin: 0 auto;
	border-bottom: 1px solid #e4e4e4
}

.canvas-wrapper button {
	float: left;
	background: 0 0;
	border: none;
	cursor: pointer
}

.addFile_for_template {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding: 5px 10px !important;
}

.upload-forms ul {
	margin-bottom: 0
}

.dragDrop_file {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding: 5px 15px
}

.dragDrop_file .btn {
	margin-right: 15px
}

.uploadForm_label {
	font-size: 16px;
	font-weight: 600
}

.dargDrop_here {
	font-size: 16px;
	font-weight: 600
}

.btn-outline-secondary:hover {
	background-color: #2c4f7b!important
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
	-webkit-box-shadow: none!important;
	        box-shadow: none!important
}

.dropdown-item:focus,
.navbar-expand-lg .navbar-nav .dropdown-menu-right .dropdown-item:hover {
	color: #fff;
	background-color: #2c4f7b
}

.errorMessage {
	font-size: 13px;
	color: #f90808
}

.login_mainParent {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column
}

.login_logo {
	margin-bottom: 20px
}

.login_logo img {
	width: 220px
}

.login_mainParent .input-group-text {
	min-width: 41px
}

.menu-item.close {
	position: absolute;
	left: -15px;
	top: 41px;
	z-index: 999;
	width: 100px;
	text-transform: uppercase;
	color: #6d6d6d;
	font-weight: 600;
	padding: 15px;
	border-radius: 3px;
	line-height: 1.1em;
	display: block;
	cursor: pointer;
	background: #fff;
	opacity: 1;
	-webkit-box-shadow: 0 0 12px rgba(51, 51, 51, .29);
	        box-shadow: 0 0 12px rgba(51, 51, 51, .29)
}

.menu-item.close:before {
	content: "";
	display: inline-block;
	height: 15px;
	width: 15px;
	background: #fdfdfd;
	position: absolute;
	left: 11px;
	top: -6px;
	-webkit-transform: rotate(-134deg);
	    -ms-transform: rotate(-134deg);
	        transform: rotate(-134deg);
	-webkit-box-shadow: 4px 4px 6px rgba(51, 51, 51, .09);
	        box-shadow: 4px 4px 6px rgba(51, 51, 51, .09)
}

.menu-item.close .item-image {
	font-size: 14px
}

i.fa-times-circle-o {
	margin-right: 5px
}

.esignpanda-header-menu i.fa-ellipsis-v {
	font-size: 30px;
	color: #b3b3b3
}

.esignpanda-header-menu .menu-wrapper {
	display: none
}

.esignpanda-header-menu:hover .menu-wrapper {
	display: block
}

.esign-hand-writing {
	font-family: 'Indie Flower', cursive;
	font-size: 21px
}

#nav-icon {
	position: relative;
	cursor: pointer;
	margin-right: auto;
	margin-left: 70px;
	width: 36px;
	height: 34px;
	background: #2c4f7a;
	border: 1px solid transparent;
	color: #fff;
	text-align: center;
	border-radius: 2px;
	font-size: 18px;
	padding: 4px;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}

#nav-icon:hover {
	background: #FFFFFF;
	border: 1px solid #2c4f7a;
	color: #2c4f7a;
}

#nav-icon span {
	display: block;
	position: absolute;
	height: 4px;
	width: 100%;
	background: #2c4f7b;
	border-radius: 9px;
	opacity: 1;
	left: 0
}

#nav-icon span:nth-child(1) {
	top: 0;
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	    transform-origin: left center
}

#nav-icon span:nth-child(2) {
	top: 8px;
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	    transform-origin: left center
}

#nav-icon span:nth-child(3) {
	top: 16px;
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	    transform-origin: left center
}

.d-md-down-none.navbar-nav {
	padding-right: 15px;
}

.addFile_for_template.border-right {
	border: none!important;
	word-break: break-word;
}

.table-wrap {
	overflow: auto
}

.useTemplate_tbl thead tr th:nth-child(3) {
	min-width: 105px
}

.useTemplate_tbl thead tr th:nth-child(4) {
	min-width: 116px
}

.documentAll_tbl thead tr th:nth-child(2) {
	min-width: 100px
}

.documentAll_tbl thead tr th:nth-child(4) {
	min-width: 112px
}

.documentAll_tbl thead tr th:nth-child(5) {
	min-width: 140px
}

.documentSigned_tbl thead tr th:nth-child(2) {
	min-width: 100px
}

.documentSigned_tbl thead tr th:nth-child(4) {
	min-width: 112px
}

.documentSigned_tbl thead tr th:nth-child(5) {
	min-width: 117px
}

.documentCanceled_tbl thead tr th:nth-child(2) {
	min-width: 100px
}

.documentCanceled_tbl thead tr th:nth-child(4) {
	min-width: 112px
}

.documentCanceled_tbl thead tr th:nth-child(5) {
	min-width: 117px
}

.documentUnsigned_tbl thead tr th:nth-child(2) {
	min-width: 100px
}

.documentUnsigned_tbl thead tr th:nth-child(4) {
	min-width: 112px
}

.documentUnsigned_tbl thead tr th:nth-child(5) {
	min-width: 117px
}

.useApp_tbl thead tr th:last-child {
	min-width: 117px
}

.inquiries_tbl th:nth-child(6) {
	min-width: 120px
}

.inquiries_tbl td .table-buttons .badge {
	cursor: pointer
}

.contact-inquiries-modal-body ul {
	padding: 0;
	margin: 0;
	list-style: none
}

.contact-inquiries-modal-body {
	max-height: 350px;
	overflow: auto
}

.contact-inquiries-modal-body ul li {
	border-bottom: 1px solid #eee;
	padding: 5px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.contact-inquiries-modal-body ul li:last-child {
	border-bottom: none
}

.contact-inquiries-modal-body ul li label {
	font-size: 16px;
	font-weight: 700;
	margin-right: 5px;
	margin-bottom: 0;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto
}

.search-container label {
	padding-left: 15px;
	padding-top: 0
}

.search-container input#searchTitle {
	line-height: 24px!important
}

.search-container input#creationDateEnd {
	line-height: 20px!important
}

.search-container input#creationDate {
	line-height: 20px!important
}

.search-container.row .input-group.mb-3.searchbar-group.col-md-8 {
	margin-left: auto
}

.search-container .col-md-2.no-padding.form-group {
	padding: 0
}

.search-container .col-md-6.no-padding.col {
	float: left
}

.search-container .col-md-5.no-padding.form-group {
	padding: 0
}

.search-container button.btn.btn-success.pull-right.col-md-5 {
	padding: 7px 0!important;
	text-align: center
}

.search-container button.btn.btn-info.pull-right.margin-right-5.col-md-5 {
	padding: 7px 0!important;
	text-align: center
}

.search-container .col-md-3.no-padding.form-group {
	padding: 0
}

.search-container.mobile {
	display: none
}

thead.thead-dark {
	border: 1px solid #2c4f7b
}

.inlineLoaderGif {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	right: 0
}

.signature-element {
	line-height: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	border: 1.5px solid #00b3e6!important
}

.esignElement {
	border: none!important
}

.esignElement input {
	border: none;
	-webkit-box-shadow: none;
	        box-shadow: none;
	outline: 0;
	background: 0 0;
	padding: 0 4px
}

.file-extension {
	margin-left: 10px;
	font-size: 12px;
	flex: 0 0 90px;
}

.d-md-down-none.navbar-nav .navbar-right-area.dropdown.nav-item .img_wrap {
	padding: 0;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	overflow: hidden
}

.d-md-down-none.navbar-nav .navbar-right-area.dropdown.nav-item .img_wrap img {
	height: 100%;
	width: 100%!important;
	-o-object-fit: cover;
	   object-fit: cover
}

#selectedFileNames {
	margin-left: auto;
	border: 1px solid rgba(229, 229, 229, .56);
	border-radius: 4px;
	background: rgba(229, 229, 229, .2)
}

#selectedFileNames span {
	padding: 0 10px;
	border-bottom: 1px solid rgba(229, 229, 229, .56);
	display: block;
	font-weight: 700
}

div#selectedFileNames div {
	padding: 0 10px;
	border-bottom: 1px solid rgba(229, 229, 229, .56);
	word-break: break-all
}

.inlineLoaderGif img {
	height: 90px
}

.NoRecordsContainerDiv {
	text-align: center;
	margin-top: 10px
}

.error404Container {
	height: 100vh;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	padding: 0 15px
}

.error404Container img {
	max-width: 100%
}

.error404MainContainer {
	background: #f8f9fa
}

.error404Text {
	font-family: 'Love Ya Like A Sister', cursive;
	color: #272727;
	font-size: 40px
}

.btn-primary.takeMeBack404 {
	background-color: #93bb24;
	border-color: #93bb24;
	margin-top: 20px
}

.btn-primary.takeMeBack404:hover {
	background-color: transparent;
	border-color: #93bb24;
	color: #93bb24
}

.click-to-sign {
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center
}

.button-wrapper {
	text-align: left;
	border-top: 1px solid #ececec
}

.button-wrapper button {
	background: 0 0;
	-webkit-box-shadow: none!important;
	        box-shadow: none!important
}

.loginpage {
	background: #f2f2f2
}

.loginpage .card-group {
	background: #fff;
	border-radius: 2px;
	-webkit-box-shadow: 0 2px 4px rgba(5, 15, 44, .1);
	        box-shadow: 0 2px 4px rgba(5, 15, 44, .1);
	padding: 30px 50px;
	color: #3f465e
}

.navbar {
	padding: 0 1rem!important
}

span.draw-it.active {
	border-left: 5px solid #2b4c76;
	background-color: #cecece
}

span.draw-it.disabled-feature,
span.draw-it.disabled-feature a {
	cursor: not-allowed
}

.image-signature-upload {
	float: right;
	width: 150px
}

.dropdown.allFontFamiliesList {
	text-align: left
}

.dropdown.allFontFamiliesList .btn.btn-secondary {
	border: 1px solid #cacaca;
	background: #fff;
	color: #a3a3a3;
	margin-top: 20px;
	min-width: 200px;
	outline: 0;
	-webkit-box-shadow: none;
	        box-shadow: none;
	position: relative
}

.dropdown.allFontFamiliesList.open .dropdown-menu {
	display: block
}

.dropdown.allFontFamiliesList.open .dropdown-menu .dropdown-item:hover {
	background: #e8e8e8
}

.modalHead_btn {
	position: relative
}

.sign-error {
	padding: 5px 10px;
	position: absolute;
	right: 20px;
	bottom: -51px;
	background: red;
	color: #fff;
	z-index: 99;
	border: 1px solid #e3e3e3
}

.sign-error:before {
	content: "";
	height: 15px;
	width: 15px;
	position: absolute;
	right: 10px;
	top: -8px;
	background: red;
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
	border: 1px solid #e3e3e3;
	border-bottom: none;
	border-right: none
}

#textCanvas {
	display: none
}

.particularElementDropped {
	border: 1px solid grey;
	border-top: none;
	text-align: center;
	line-height: 0
}

.head-nav li.menu-has-children ul {
	-webkit-transform: translateY(20px);
	    -ms-transform: translateY(20px);
	        transform: translateY(20px);
	-webkit-transition: trasnform .4s;
	-o-transition: trasnform .4s;
	transition: trasnform .4s;
	-webkit-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	visibility: hidden;
	opacity: 0;
	padding: 0 0;
	margin-top: 11px;
	background: #fff;
	border: none;
	border-radius: 0 0 3px 3px;
	-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .2);
	        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .2)
}

.head-nav li.menu-has-children:active ul,
.head-nav li.menu-has-children:focus ul,
.head-nav li.menu-has-children:hover ul {
	-webkit-transform: translateY(0);
	    -ms-transform: translateY(0);
	        transform: translateY(0);
	-webkit-transition: trasnform .4s;
	-o-transition: trasnform .4s;
	transition: trasnform .4s;
	-webkit-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	visibility: visible;
	opacity: 1;
	margin: 0
}

.head-nav li.menu-has-children ul li:hover a {
	border-radius: 0
}

.navbar-right-area .dropdown-menu {
	padding: 5px 0;
	border: none;
	border-radius: 0 0 3px 3px;
	-webkit-box-shadow: 0 5px 6px 2px rgba(0, 0, 0, .2);
	        box-shadow: 0 5px 6px 2px rgba(0, 0, 0, .2)
}

.navbar-right-area .dropdown-menu.show {
	display: block;
	-webkit-transform: translate3d(0, 0, 0)!important;
	        transform: translate3d(0, 0, 0)!important;
	top: 100%!important;
	left: auto!important;
	right: 0!important;
	margin-top: 0
}

.navbar-right-area .dropdown-menu h6.dropdown-header {
	padding: 0 0;
	margin: auto
}

.navbar-right-area .dropdown-menu h6.dropdown-header a button {
	padding: 6px 15px;
	color: #000;
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	display: block;
	font-size: 13px;
	text-transform: none;
	outline: 0
}

.navbar-right-area .dropdown-menu h6.dropdown-header a button i {
	margin-right: 5px
}

.navbar-right-area .dropdown-menu h6.dropdown-header a:active button,
.navbar-right-area .dropdown-menu h6.dropdown-header a:focus button,
.navbar-right-area .dropdown-menu h6.dropdown-header a:hover button {
	color: #fff;
	background: #274a76
}
.navbar-right-area .dropdown-menu h6.dropdown-header a:active button i,
.navbar-right-area .dropdown-menu h6.dropdown-header a:focus button i,
.navbar-right-area .dropdown-menu h6.dropdown-header a:hover button i{
	color: #fff;
}
.login-form #right {
	background: #fff
}

h1.headingsignin {
	margin: 0 0 20px 0;
	font-size: 30px;
	color: #0e2854;
	font-weight: 400
}

.login-form .form-input {
	margin-top: 15px
}

.login-form .form-input>span {
	font-weight: 600;
	padding: 0;
	margin: 0 0;
	font-size: 14px;
	color: #333
}

.login-form input.form-control {
	font-weight: 400;
	padding: 4px 10px;
	line-height: normal;
	color: #000;
	border-radius: 5px 5px 0 0;
	border: none;
	border-bottom: 2px solid #33619a;
	font-size: 13px;
	background: 0 0
}

.login-form-field {
	padding: 0 20px
}

.login-form-field .loginbutton {
	display: block;
	margin: 30px auto 0 auto
}

.login-form-field .social-links {
	margin-top: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	position: relative
}

.login-form-field .social-links:after {
	content: "or";
	position: absolute;
	left: 40%;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	    -ms-transform: translate(0, -50%);
	        transform: translate(0, -50%);
	color: #000;
	font-size: 22px;
	font-weight: 700;
	display: none;
}

.login-form-field .social-links .loginbutton {
	margin: 0
}

.login-form-field .social-links ul {
	padding: 0;
	margin: 0;
}

.login-form-field .social-links ul li a {
	position: relative;
	display:block;
	cursor: pointer;
	width: 150px;
}

.login-form-field .social-links ul li a.facebook{
	width: calc(100% - 4px);
    background: #4285f4;
    border-radius: 3px;
    overflow: hidden;
	margin-left: 2px;
	display: flex;
    align-items: center;
}
.login-form-field .social-links ul li a.facebook i{
	background: #fff;
    color: #2c4f7a;
    padding: 8px 11px 8px 12px;
    margin: 1px;
    border-radius: 3px 0 0 3px;
}
.login-form-field .social-links ul li a.facebook span{
	font-size: 10px;
    font-weight: 800;
    margin-left: 5px
}
.login-form-field .social-links ul li a.envelope{
	width: 160px;
}
/* .login-form-field .social-links ul li a:before {
	transform: scale(1);
	-ms-transform: scale(1);
	-webkit-transform: scale(1);
	content: " ";
	width: 45px;
	height: 45px;
	border-radius: 100%;
	display: block;
	-webkit-transition: all 265ms ease-out;
	-o-transition: all 265ms ease-out;
	transition: all 265ms ease-out
} */

/* .login-form-field .social-links ul li a.facebook:before {
	background: #3c57a4
}

.login-form-field .social-links ul li a.envelope:before {
	background: #d94d42
}

.login-form-field .social-links ul li a:before {
	-webkit-transform: scale(0);
	    -ms-transform: scale(0);
	        transform: scale(0);
	-webkit-transition: all 265ms ease-in;
	-o-transition: all 265ms ease-in;
	transition: all 265ms ease-in
}

.login-form-field .social-links ul li a:hover:before {
	-webkit-transform: scale(1);
	    -ms-transform: scale(1);
	        transform: scale(1)
} */

/* .login-form-field .social-links ul li a.facebook i {
	font-size: 16px;
	color: #2c4f7a;
	background: #3c57a4;
}

.login-form-field .social-links ul li a.envelope i {
	font-size: 26px;
	color: #d94d42;
	background: #d94d42;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-transition: all 265ms ease-in;
	-o-transition: all 265ms ease-in;
	transition: all 265ms ease-in
}

.login-form-field .social-links ul li a.facebook:hover i {
	color: #fff;
	background: #fff;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.login-form-field .social-links ul li a.envelope:hover i {
	color: #fff;
	background: #fff;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.login-form-field .social-links ul li a i {
	color: #fff;
	position: absolute;
	top: 12px;
	left: 14px;
	-webkit-transition: all 265ms ease-out;
	-o-transition: all 265ms ease-out;
	transition: all 265ms ease-out;
	font-size: 20px
}
.login-form-field .social-links ul li a i.fa-google{
	top: 10px;
	left: 12px;
} */
.login-acction a,
.login-acction button.btn-link {
	font-size: 12px;
	color: #333;
	line-height: normal;
	letter-spacing: normal;
	padding: 0
}

.login-acction a:active,
.login-acction a:focus,
.login-acction a:hover {
	color: #44b319
}

.login-acction button.btn-link:active,
.login-acction button.btn-link:focus,
.login-acction button.btn-link:hover {
	color: #44b319;
	text-decoration: none
}

.login-acction {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: auto auto;
	grid-template-columns: auto auto;
	margin-top: 10px
}

.send-email-btn {
	margin-top: 15px
}

.dashboard-header {
	height: 60px;
	background: #fff;
	-webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, .1);
	box-shadow: 1px 1px 10px rgba(0, 0, 0, .1)
}

.dash-logo {
	margin: 0 0;
	padding: 0 10px;
	-ms-flex-item-align: normal;
	    -ms-grid-row-align: normal;
	    align-self: normal;
}

.dash-logo>img {
	width: 140px
}

.right-body-content .page_title {
	margin-top: 15px;
	margin-bottom: 25px;
	color: #333;
	line-height: normal;
	font-size: 24px;
	padding: 0;
	border-radius: 0;
	font-weight: 400
}

.inforide {
	background: #fff;
	-webkit-box-shadow: 1px 2px 8px 0 #f1f1f1;
	        box-shadow: 1px 2px 8px 0 #f1f1f1;
	border-radius: 4px;
	border: none;
	padding: 15px 20px 15px 20px
}

.inforide.green .ride-icon {
	background: rgba(68, 179, 25, .2);
	border: 1px solid #44b319;
	color: #44b319
}

.inforide.blue .ride-icon {
	background: rgba(34, 131, 245, .2);
	border: 1px solid #2283f5;
	color: #2283f5
}

.inforide.purple .ride-icon {
	background: rgba(97, 74, 190, .2);
	border: 1px solid #614abe;
	color: #614abe
}

.ride-icon {
	border-radius: 50px;
	width: 50px;
	height: 50px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex
}

.ride-icon>i {
	font-size: 22px;
	text-align: center;
	margin: auto;
	display: block;
	line-height: 48px
}

.fontsty {
	display: inline-block;
	vertical-align: middle;
	text-align: right;
	width: calc(100% - 50px)
}

.fontsty h4 {
	color: #98a6ad;
	font-size: .875rem;
	text-align: right;
	margin: auto
}

.fontsty h2 {
	color: #323a46;
	font-size: 1.5rem;
	margin: auto;
	text-align: right;
	font-weight: 600;
	margin-bottom: 5px
}

.info-link {
	background: 0 0;
	border: none;
	display: block;
	margin-top: 15px;
	border-top: 1px solid #eee;
	padding: 25px 0
}

.info-link>a {
	font-size: 14px;
	color: #6c757d;
	display: block;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.info-link>a:hover {
	color: #44b319
}

.info-link>a>i {
	float: right;
	font-size: 17px;
	color: #6c757d;
	padding: 0 14px;
	-webkit-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out
}

.info-link>a:hover>i {
	padding: 0 20px 0 14px
}

.content-wrapper {
	min-height: calc(100vh - 56px);
	padding-top: 4rem;
	overflow-x: hidden;
	background: 0 0
}

.stat-value {
	display: block;
	margin: 0 15px;
	border-bottom: 1px solid #eee;
	padding: 0 0 20px 0
}

.stat-graph-wrap {
	margin: 30px auto;
	display: block
}

.stat-graph {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: auto auto auto;
	grid-template-columns: auto auto auto
}

.stat-graph .graph-box {
	background: #fff;
	-webkit-box-shadow: 1px 2px 8px 0 #f1f1f1;
	        box-shadow: 1px 2px 8px 0 #f1f1f1;
	border-radius: 4px;
	border: none;
	padding: 15px 20px;
	margin: 0 15px
}

.graph-header {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: auto auto;
	grid-template-columns: auto auto;
	border-bottom: 1px solid #eee;
	padding: 10px 0;
	padding-top: 0;
	margin-bottom: 15px
}

.graph-header h3 {
	font-size: 1rem;
	font-weight: 400;
	color: #343a40;
	line-height: 22px;
	text-align: left
}

.graph-header span {
	font-size: 22px;
	font-weight: 600;
	color: #343a40;
	line-height: 22px;
	text-align: right
}

.CircularProgressbar .CircularProgressbar-text {
	font-size: 14px!important;
	font-weight: 600
}

.right-body-content h3.page_title {
	margin: 10px 0 10px 0;
	font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

.table-cover {
	background: #fff;
	border: none;
	-webkit-box-shadow: 0 0 4px 2px rgba(170, 170, 170, .1);
	        box-shadow: 0 0 4px 2px rgba(170, 170, 170, .1)
}

.table-cover .table thead th {
	border-bottom: 2px solid #2c4f7b
}

.react-bootstrap-daterangepicker-container {
	display: block!important
}

.table-cover .table>tbody>tr {
	background: #fff;
	border-bottom: 1px solid #eee
}

.table-cover .table th {
	border-top: none;
	font-size: 16px;
	font-weight: 700;
	color: #333;
	padding: 10px 10px;
	line-height: normal
}

.table-cover .table td {
	font-size: 13px;
	font-weight: 400;
	color: #666;
	padding: 8px 10px;
	line-height: normal
}

.table-cover .table-hover tbody tr {
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.table-cover .table-hover tbody tr:hover {
	background: #f2f8ff
}

.table-cover tbody .table-buttons .badge {
	display: inline-block;
	padding: 4px 2px;
	margin: 0 3px;
	font-size: 15px;
	font-weight: 400;
	line-height: 15px;
	text-align: center;
	color: #fff;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 2px;
	width: 24px;
	height: 24px;
	outline: 0
}

.table-cover tbody .table-buttons .badge:active,
.table-cover tbody .table-buttons .badge:focus,
.table-cover tbody .table-buttons .badge:hover {
	color: #fff
}

.table-cover .pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-flow: row;
	        flex-flow: row;
	-ms-flex-direction: row;
	    flex-direction: row;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	list-style: none;
	border-radius: 0;
	border: 1px solid #2c4f7b;
	padding: 0 0;
	margin: 10px auto
}

.table-cover ul.pagination li a {
	padding: 4px 12px;
	margin: 0 .5px;
	border-right: 1px solid #fff;
	display: inline-block;
	background: #f3f8ff;
	color: #333;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s
}

.table-cover ul.pagination li:first-child a,
.table-cover ul.pagination li:last-child a {
	background: #2c4f7b;
	color: #fff
}

.table-cover ul.pagination li a:hover {
	color: #fff;
	background: #2c4f7b
}

.table-cover ul.pagination li.active a {
	color: #fff;
	background: #2c4f7b
}

.setting-tabs-parent {
	margin: 10px 10px
}

.setting-tabs-parent .nav-tabs {
	border: none;
	margin: 0 auto;
	margin-bottom: 15px;
	background: #fff;
	padding: 6px 6px;
	border-radius: 4px
}

.setting-tabs-parent .nav-tabs .nav-link {
	background: #f4f4f4;
	border-radius: 4px;
	border: none;
	font-size: 16px;
	font-weight: 600;
	color: #333;
	text-align: center;
	padding: 8px 20px;
	margin-right: 5px;
	line-height: normal;
	height: 100%
}

.setting-tabs-parent .nav-tabs .nav-link.active {
	background: #2c4f7b;
	border-radius: 4px;
	border: none;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	text-align: center;
	padding: 8px 20px;
	line-height: normal;
	height: 100%
}

.nav-tabs .nav-link.active,
.setting-tabs-parent .nav-tabs .nav-item.show .nav-link {
	border-right: 1px solid #e3dfdf
}

.custom-tooltip {
	position: relative
}

.custom-tooltip span.text {
	width: auto;
	position: absolute;
	text-align: center;
	font-size: 14px;
	background: #fff;
	color: #000;
	right: auto;
	left: 50%;
	top: -34px;
	display: none;
	padding: 5px 10px;
	border-radius: 4px;
	-webkit-box-shadow: 0 0 5px 0 #2c4f7a;
	        box-shadow: 0 0 5px 0 #2c4f7a;
	z-index: 9;
	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%);
	white-space: nowrap
}

.custom-tooltip span.icon {
	background: #2c4f7a;
	color: #fff;
	padding: 4px 9px;
	cursor: pointer;
	font-size: 14px;
	border-radius: 50%
}

.custom-tooltip:hover span.text {
	display: block
}

div#controlled-tab-example-tabpane-emailTemplate h3.page_title.m-3 {
	margin: 0
}

label.current-package {
	margin: 0;
	margin-top: 5px
}

label.current-package span {
	font-size: 18px;
	font-weight: 700
}

.addFile_for_template .theme_btn.tem-name {
	width: calc(100% - 20px);
	margin: 0 auto;
	padding: 5px 2px;
	cursor: pointer
}

textarea.form-control {
	height: 80px
}

textarea.form-control.height-auto {
	height: auto
}

table.table.table-hover {
	margin-bottom: 0
}

.table-cover .table td a:hover {
	color: #35547d
}

.table-cover .table td a {
	font-weight: 600;
	color: #007bff
}

.setting-tabs-parent .nav-tabs .nav-link:hover {
	background: #2c4f7b;
	color: #fff
}

.form-check label.custom-checkbox {
	margin-left: -20px
}

.form-check label.custom-checkbox .checkmark {
	top: 2px
}

.footer-widget form~div~.alert {
	width: 100%;
	margin: 10px 0 0 0
}

.ReactModalPortal .ReactModal__Content.ReactModal__Content--after-open {
	max-width: 90%
}

.user-signpad-modal {
	position: absolute;
	border: 1px solid #ccc;
	background: #fff;
	border-radius: 4px;
	outline: 0
}

.custom-show .table-heading {
	margin-bottom: 15px;
	margin-top: 15px
}

.custom-show .stat-graph {
	display: block
}

.custom-show .graph-box {
	width: 100%;
	margin: 0
}

.preview-esignpanda {
	line-height: normal;
	padding: 6px 8px;
	background: #e8e8e8;
	margin-top: 3px;
	margin-left: -6px
}

.multi-select-input {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center
}

.css-2b097c-container {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto
}

.css-yk16xz-control {
	border-radius: 4px 0 0 4px!important
}

.multi-select-input .btn.theme_btn.add {
	padding: 6px 15px!important;
	border-radius: 0
}

.multi-select-input a.btn.theme_btn.add {
	border-radius: 0 4px 4px 0;
	border-left: 1px solid #ccc!important
}

.feature-tag-wrap {
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.feature-tag {
	background: #eee;
	padding: 0 6px;
	border-radius: 4px;
	border: 1px solid #ddd;
	font-size: 14px;
	margin-right: 4px;
	margin-bottom: 4px
}

.contact-inquiries-modal-body ul li .feature-tag:nth-last-of-type() {
	margin-right: 0
}

.package-expire-alert {
	text-align: center;
	color: #fff;
	padding: 10px;
	font-weight: 700;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 99;
	max-width: 500px;
	margin: 0 auto
}

.package-expire-alert a {
	text-decoration: underline!important;
	color: #fff
}

.package-expire-alert a:active,
.package-expire-alert a:focus,
.package-expire-alert a:hover {
	color: #fff
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0
}

.cms-page-listing-tbl thead tr th:first-child {
	width: 200px
}

.cms-page-listing-tbl thead tr th:nth-child(2) {
	width: 200px
}

.main-body-content .metismenu.collapsed {
	width: 250px;
	-webkit-transform: translate(-250px);
	-ms-transform: translate(-250px);
	    transform: translate(-250px);
}

.main-body-content .metismenu {
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	    transform: translate(0);
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}

.metismenu.collapsed~.right-body-content {
	margin-left: 0;
	width: 100%;
}
.gdrive_btn{
	background: none;
    border: none;
	padding: 8px 0;
	outline: none !important;
}
.gdrive_btn img{
	width: 40px;
}
.modal-dialog.email-success-modal {
    margin: 0 auto;
    transform: translateY(-50%) !important;
    top: 45%;
}
.email-success-modal .modal-content{
	border: 3px solid #2073b6;
    text-align: center;
}
.email-success-modal .select-template-modalInner{
	font-size: 18px;
    color: #44b319;
}
.disableDocument td:nth-of-type(2):hover .__react_component_tooltip.place-top.type-dark+.__react_component_tooltip.place-top.type-dark{
	display: none !important;
}
.incoming_file_container{
	padding-top: 90px;
}
.incoming_file_container .your_file{
	text-align: center;
}
.incoming_file_container .your_file h3{
	font-weight: 500;
    font-size: 22px;
}
.file-wrap span{
	font-size: 12px;
}
.file-circle{
	height: 70px;
	width: 70px;
	border-radius: 50%;
	border: 1px solid #2c4f7a;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto 0 auto;
}
.file-circle i{
	font-size: 25px;
    color: #2c4f7a;
}
.Who-needs-sign{
	text-align: center;
	max-width: 700px;
	margin: 0 auto;
}
.Who-needs-sign h3{
	font-weight: 500;
	font-size: 22px;
	margin: 40px 0 40px 0;
}
.sign_chooser{
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-flow: wrap;
	margin-left: -10px;
    margin-right: -10px;
}
.sign_chooser li{
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
	padding: 0 10px;
}
.sign_chooser .signing-option {
    border: 1px solid #E6E6E6;
    background: white;
    color: #4E4E4E;
    text-transform: uppercase;
    cursor: pointer;
    padding: 20px;
    font-size: 15px;
    font-weight: 600;
	text-align: center;
	transition: all 0.3s;
	border-radius: 4px;
}
.sign_chooser .signing-option:hover,.sign_chooser .signing-option:focus,.sign_chooser .signing-option.active{
	background: #efefef;
	box-shadow: 0 0 6px rgba(96, 96, 96, 0.21);
}
.sign_chooser .signing-option .img-wrap {
    min-height: 120px;
}
.signing-option img {
    width: 166px;
}
.sign_chooser .signing-option.just-me img{
	width: 80px;
}
.sign_chooser .signing-option h4{
	font-size: 15px;
    font-weight: 700;
}
.sign_chooser + p{
	margin-top: 15px;
	padding-bottom: 0;
	font-size: 16px;
}
.warning-message-wrap{
	padding-top: 130px;
}
.warning-message{
	max-width: 500px;
    margin: 0 auto;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
}
.warning-message .head{
	background: #f65656;
    border-radius: 20px 20px 0 0;
    text-align: center;
    padding: 10px 0;
}
.warning-message.success .head{
	background: #44b319;
}
.warning-message .head .img-wrap img{
    width: 80px;		
}
.warning-message .body{
	background: #f8f6f6;
    text-align: center;
    padding: 25px 15px;
    border-radius: 0 0 20px 20px;
}
.warning-message .body h3{
	font-size: 32px;
}
.warning-message .body p{
	font-size: 20px;
    padding: 10px 0;
}
.warning-message .body a{
	display: inline-block;
    padding: 4px 30px;
    background: #f65656;
    color: #fff;
	border-radius: 50px;
	border: 1px solid #f65656;
	transition: all 0.3s;
}
.warning-message .body a:hover{
	background: #fff;
	color: #f65656;
}
.warning-message.success .body a{
	border: 1px solid #44b319;
	background: #44b319;
}
.warning-message.success .body a:hover{
	background: #fff;
	color: #44b319;
}
.ChromApp-welcome{
	margin-top: 20px;
    height: calc(100vh - 115px);
    padding-top: 150px;
    text-align: center;
}
.ChromApp-welcome h3{
	font-size: 45px;
    margin-bottom: 20px;
}
.ChromApp-welcome h3 span{
	color: #44b319;
}
.ChromApp-welcome p{
	font-size: 20px;
    max-width: 500px;
    margin: 0 auto;
}

@media (max-width:1330px) {
	.inner-box.col-lg-7{flex: 0 0 68.333333%;max-width: 68.333333%;}
	#main .form-row .col-lg-7{flex: 0 0 68.333333%;max-width: 68.333333%;}
}
@media (max-width:1199px) {
	.login_mainParent .p-4.card {
		padding: 0!important
	}
	.login_mainParent .py-5 {
		padding-top: 1rem!important;
		padding-bottom: 1rem!important
	}
	.inforide .row {
		margin-right: -15px!important
	}
	.ridetwo {
		margin-left: 0
	}
	.ridethree {
		margin-left: 0
	}
	.inforide .row .col-lg-3 {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 30%;
		        flex: 0 0 30%;
		max-width: 30%
	}
	.inforide .row .col-lg-9 {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 70%;
		        flex: 0 0 70%;
		max-width: 70%
	}
	.metismenu-item ul.metismenu-container.visible>li>button {
		padding-left: 50px
	}
	.search-container.desktop .form-control {
		padding: 6px 5px;
		font-size: 13px
	}
	.search-container.desktop .btn {
		height: 40px;
		font-size: 13px
	}
	.canceledpercentage .CircularProgressbar,
	.signedpercentage .CircularProgressbar,
	.unsignedpercentage .CircularProgressbar {
		width: 50%!important
	}
}

@media (max-width:991px) {
	.inner-box.col-lg-7 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	#main .form-row .col-lg-7{
		flex: 0 0 100%;
		max-width: 100%;
	}
	.login_mainParent .col-md-8 {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
		max-width: 100%
	}
	.fontsty h4 {
		font-size: 16px
	}
	.fontsty h2 {
		font-size: 22px
	}
	.inforide .row .col-lg-3 {
		padding: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center
	}
	.inforide .fa {
		height: 40px;
		width: 40px;
		line-height: 40px;
		font-size: 18px
	}
	.main-body-content .metismenu {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 25%;
		        flex: 0 0 25%;
		width: 25%
	}
	.right-body-content {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 75%;
		        flex: 0 0 75%;
		margin-left: 25%;
		width: 75%
	}
	.search-container.mobile {
		display: block
	}
	.search-container.desktop {
		display: none
	}
	label.custom-file-upload {
		margin-right: 10px
	}
	.elementsContainerHead .modalHead_btn {
		margin-top: 10px
	}
	.elementsContainerHead {
		display: block
	}
	.canceledpercentage .CircularProgressbar,
	.signedpercentage .CircularProgressbar,
	.unsignedpercentage .CircularProgressbar {
		width: 65%!important
	}
	.stat-graph .graph-box {
		padding: 5px 23px
	}
	.esignpanda-header {
		padding: 5px 10px
	}
	.esignpanda-logo {
		width: 100px
	}
	.esignpanda-right-container button {
		padding: 3px 15px;
		font-size: 14px
	}
	.modal-body.esignModal-body {
		min-height: 225px
	}
	.esignModal-head span {
		padding: 5px 0
	}
	.esignModal-head span+i {
		top: 10px
	}
	.eSign-right-panel {
		padding-bottom: 0
	}
	.esignModal-footer.modal-footer {
		padding: .5rem
	}
}

@media (min-width:769px) {
	.wrapper.document.fix .container-fluid.side {
		max-width: calc(100% - 250px);
		margin-left: 250px
	}
	.wrapper.document.fix nav#sidebar {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%
	}
	.border-box .jump-links {
		word-break: break-all
	}
}

@media (max-width:767px) {
	.d-md-down-none.navbar-nav{padding-right: 0;}
	#nav-icon {display: block;margin-left: auto}
	.right-body-content {
		margin-left: 0;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
		width: 100%
	}
	.main-body-content .metismenu {
		width: 250px;
		-webkit-transform: translate(-250px);
		    -ms-transform: translate(-250px);
		        transform: translate(-250px);
		z-index: 999;
		-webkit-transition: all .3s;
		-o-transition: all .3s;
		transition: all .3s
	}
	.main-body-content .metismenu.collapsed {
		-webkit-transform: translate(0);
		    -ms-transform: translate(0);
		        transform: translate(0)
	}
	.elementsContainerHead {
		display: block;
		width: 100%;
		padding: 10px
	}
	.elementsContainerHead .elementsContainer {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap
	}
	.elementsContainerHead .dragable_elements {
		margin-right: 10px;
		padding: 5px 10px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 33.333333%;
		        flex: 0 0 33.333333%;
		max-width: calc(33.333333% - 10px);
		margin-bottom: 10px
	}
	.elementsContainerHead .dragable_elements:nth-child(3) {
		margin-right: 0;
		max-width: 33.333333%
	}
	.elementsContainerHead .custom_theme_btn {
		width: 100%
	}
	.page_title {
		margin-bottom: 20px;
		color: #2c4f7b;
		line-height: 2;
		font-size: 22px;
		padding: 5px 15px;
		border-radius: 5px;
		position: unset!important;
		width: 100%
	}
	.stat-graph .graph-box {
		padding: 5px 12px
	}
	.eSign-lef-panel {
		max-width: 26%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 26%;
		        flex: 0 0 26%
	}
	.eSign-right-panel {
		max-width: 74%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 74%;
		        flex: 0 0 74%
	}
	.eSign-lef-panel .draw-it {
		font-size: 12px;
		padding: 5px
	}
	#signature-pad {
		margin: 0 10px
	}
	.ReactModal__Overlay {
		z-index: 999
	}
	.user-signpad-modal {
		top: 10px!important
	}
	.ReactModalPortal .ReactModal__Content.ReactModal__Content--after-open.user-signpad-modal {
		max-width: 98%
	}
	.Who-needs-sign{max-width: 700px;}
	.sign_chooser .signing-option{padding: 10px;}
	.sign_chooser .signing-option .img-wrap {min-height: 100px;}
	.sign_chooser .signing-option.just-me img {width: 65px;}
	.Who-needs-sign h3{margin: 20px 0;font-size: 18px}
	.incoming_file_container .your_file h3{font-size: 18px;}
	.sign_chooser .signing-option h4{font-size: 14px;}
	.file-circle {height: 50px;width: 50px;}
	.file-circle i{font-size: 18px;}
}

@media (max-width:575px) {
	.login_mainParent .d-md-down-none.card {
		width: 100%!important
	}
	.fullhightflex {
		display: block
	}
	.login_mainParent {
		padding: 20px 0 30px 0
	}
	.login_mainParent .card-body {
		padding: .7rem
	}
	.right-body-content .col-lg-4.col-md-4.col-sm-6.col-12.mb-2.mt-4 {
		margin-top: .5rem!important
	}
	.searchbar-group {
		width: 100%!important
	}
	.searchbar-group-dropdown {
		width: calc(100% - 60px)
	}
	.col-md-6.col-sm-6.last-button {
		text-align: center!important;
		margin-bottom: 10px
	}
	span.tooltip.info-role .tooltiptext {
		right: -30px
	}
	.stat-graph {
		display: block
	}
	.stat-graph .graph-box {
		margin-bottom: 20px
	}
	.canceledpercentage .CircularProgressbar,
	.signedpercentage .CircularProgressbar,
	.unsignedpercentage .CircularProgressbar {
		width: 40%!important
	}
	.setting-tabs-parent .nav-tabs {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	.setting-tabs-parent .nav-tabs .nav-link {
		-webkit-box-flex: 1;
		    -ms-flex: 1 0 auto;
		        flex: 1 0 auto;
		margin-bottom: 5px
	}
	.setting-tabs-parent .nav-tabs .nav-link:last-of-type {
		margin-bottom: 0
	}
	.dropdown.allFontFamiliesList.open .dropdown-menu {
		max-height: 140px;
		overflow: auto
	}
	.sign_chooser li{flex: 0 0 100%;max-width: 100%;margin-bottom: 10px;}
	.sign_chooser .signing-option .img-wrap {min-height: auto;margin-bottom: 10px;}
	.incoming_file_container {padding-top: 75px;}
	.file-circle{margin: 10px auto 0 auto;}
	.Who-needs-sign h3 {margin: 15px 0;}
	.sign_chooser .signing-option.just-me img {width: 50px;}
	.signing-option img {width: 115px;}
	.sign_chooser + p{margin-top: 5px;}
}

@media (max-width:480px) {
	.esignpanda-header {
		padding: 10px 15px
	}
	.esignpanda-header-menu {
		margin-left: 0;
		margin-top: 3px
	}
	.esignpanda-logo {
		width: 100px
	}
	.esignpanda-right-container button {
		padding: 5px 10px;
		font-size: 13px
	}
	.login_mainParent .mx-4 card {
		margin: 0!important
	}
	.navbar-brand img {
		width: 120px
	}
	.d-md-down-none.navbar-nav .navbar-right-area.dropdown.nav-item a {
		padding: 0
	}
	.page_title {
		font-size: 1.4rem
	}
	label.custom-file-upload {
		margin-right: 10px;
		font-size: 14px;
		width: 60px
	}
	.inner-box form input[type=file] {
		width: 130px
	}
	.main-body-content .metismenu {
		top: 53px
	}
	.elementsContainerHead .dragable_elements {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 50%;
		        flex: 0 0 50%;
		max-width: 50%;
		margin-right: 0
	}
	.elementsContainerHead .dragable_elements:nth-child(3) {
		margin-right: 0;
		max-width: 50%
	}
	.elementsContainerHead .dragable_elements:last-child {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
		max-width: 100%
	}
	.esignModal-footer button {
		font-size: .8rem;
		line-height: normal
	}
	#signature-pad {
		padding: 5px 0
	}
	.eSign-lef-panel .draw-it {
		padding: 5px 5px;
		font-size: 12px
	}
	.eSign-right-panel {
		padding-bottom: 0
	}
	.esignModal-head span {
		padding: 20px 0 0 0
	}
}

.three-dots {
	position: relative
}

.three-dots button {
	padding: 4px 12px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin-top: 0px;
	margin-left: 10px
}

.three-dots i {
	font-size: 20px
}

.three-dots ul {
	-webkit-transform: translateY(20px);
	-ms-transform: translateY(20px);
	    transform: translateY(20px);
	-webkit-transition: trasnform .4s;
	-o-transition: trasnform .4s;
	transition: trasnform .4s;
	-webkit-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	padding: 0 0;
	margin-top: 11px;
	background: #fff;
	border: none;
	border-radius: 0 0 3px 3px;
	-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .2);
	        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .2);
	margin: 0;
	position: absolute;
	top: 100%;
	right: 0;
	z-index: 99;
	margin: 0;
	padding: 0;
	list-style: none
}

.three-dots:active ul,
.three-dots:focus ul,
.three-dots:hover ul {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	    transform: translateY(0);
	-webkit-transition: trasnform .4s;
	-o-transition: trasnform .4s;
	transition: trasnform .4s;
	-webkit-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	height: auto;
	overflow: visible;
	visibility: visible;
	opacity: 1;
	margin: 0
}

.three-dots ul li {
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	border-bottom: 1px solid #f2f2f2;
	min-width: 180px;
	position: relative;
	white-space: nowrap
}

.three-dots ul li a {
	padding: 6px 15px;
	color: #000;
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	display: block;
	font-size: 13px;
	text-transform: none;
	cursor: pointer
}

.three-dots ul li:hover a {
	background: #274a76;
	color: #fff!important
}

.is-disable {
	pointer-events: none;
	cursor: default;
	text-decoration: none;
	color: #000;
	background-color: grey!important;
	opacity: .5
}

.tabs-back {
	display: inline-block;
	width: 100%;
	margin-bottom: 0;
	padding: 0
}

.tabs-back ul#myTab {
	-webkit-box-pack: left;
	    -ms-flex-pack: left;
	        justify-content: left;
	border: none;
	margin: 0 auto;
	margin-bottom: 15px;
	background: #fff;
	padding: 6px 6px;
	border-radius: 4px
}

.tabs-back ul#myTab li.nav-item .nav-link {
	border-radius: 4px;
	border: none;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	padding: 8px 20px;
	line-height: normal;
	background: #f4f4f4;
	color: #333;
	margin-right: 5px;
	cursor: pointer
}

.tabs-back ul#myTab li.nav-item .nav-link.active,
.tabs-back ul#myTab li.nav-item .nav-link:hover {
	background: #2c4f7b;
	color: #fff;
	border-radius: 4px;
	border: none;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	padding: 8px 20px;
	line-height: normal;
	margin-right: 5px
}

.tabs-back .search-container {
	display: inline-block;
	width: 100%;
	padding: 0 10px
}

.tabs-back .search-container .searchbar-group {
	max-width: 300px
}

.entry-content li span.status {
	position: absolute;
	right: 10px;
	width: 13px
}

.entry-content li {
	position: relative
}

.basic-multi-select:focus,
.basic-multi-select:hover {
	z-index: 99
}

.freelink {
	left: 0;
	right: 0;
	bottom: 0;
	margin-top: -8px;
	margin-bottom: 10px
}

.freelink a {
	color: #1f73b7!important;
	cursor: pointer
}

.freelink a:active,
.freelink a:focus,
.freelink a:hover {
	color: #44b319!important
}

.plan-inner .btnSpace {
	padding: 2em 0;
	text-align: center
}
