body {
	background: #fff;
	color: #444;
	font-family: 'Nunito Sans', sans-serif!important
}

p {
	font-size: 14px;
	font-weight: 400
}

.btn-blue {
	color: #fff;
	background: #274a76;
	border: 1px solid #274a76;
	border-radius: 30px;
	outline: 0!important;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}

.btn-blue:active,
.btn-blue:focus,
.btn-blue:hover {
	color: #fff;
	background: 0 0;
	border: 1px solid #fff
}

.btn-outline-blue {
	color: #274a76;
	background: 0 0;
	border: 1px solid #274a76;
	border-radius: 30px;
	outline: 0;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}

.btn-outline-blue:active,
.btn-outline-blue:focus,
.btn-outline-blue:hover {
	color: #fff;
	background: #274a76;
	border: 1px solid #274a76
}

a {
	color: #50d8af;
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s
}

a:active,
a:focus,
a:hover {
	color: #51d8af;
	outline: 0;
	text-decoration: none
}

p {
	padding: 0;
	margin: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
	margin: 0;
	padding: 0;
	font-family: 'Nunito Sans', sans-serif!important
}

h2 {
	font-size: 50px;
	font-weight: 300
}

.home h2 {
	color: #000
}

h3 {
	font-size: 18px;
	font-weight: 700
}

#header {
	padding: 25px 0;
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
	z-index: 997;
	background: 0 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%
}

#header.landingpageheader {
	background: url(../img/banner-bg.jpg) no-repeat;
	background-size: cover;
	padding: 15px 0
}

#header .logo-size img {
	width: 130px;
	height: 100%
}

.logo-size {
	display: inline-block
}

.logo-size a {
	display: block
}

#intro {
	width: 100%;
	height: 600px;
	position: relative;
	background: url(../img/banner-bg.jpg) no-repeat;
	background-size: cover;
	padding-top: 90px
}

#intro img {
	position: absolute;
	right: 15%;
	height: 85%
}

#intro .circle {
	position: absolute;
	left: 10%;
	top: 20%;
	width: 50%;
	height: 50%;
	padding-right: 2rem
}

#intro .caption {
	color: #fff;
	padding-top: 8rem;
	position: relative
}

#intro .caption:before {
	content: '';
	background: url(../img/text-before.png) no-repeat;
	width: 337px;
	height: 30px;
	position: absolute;
	right: calc(100% - 40px);
	bottom: 85px
}

#intro .caption p {
	padding-bottom: 20px;
	padding-top: 10px;
	font-size: 16px;
	font-weight: 400
}

#intro .caption a.btn-w {
	margin-right: 5px
}

#intro h1.display-1 {
	font-size: 50px;
	font-weight: 700;
	text-transform: unset;
	line-height: 50px
}

#call-to-action .btn-w {
	color: #0c0c0c;
	font-weight: 700;
	background-color: #fafafa;
	border-radius: 50px;
	padding: 12px 30px;
	font-size: 14px;
	border: 3px solid #274a76;
	-webkit-box-shadow: 0 0 0 1px #ccc;
	        box-shadow: 0 0 0 1px #ccc
}

#call-to-action .btn-w:hover {
	color: #44b319
}

.btn-w {
	color: #274a76;
	font-size: 14px;
	font-weight: 700;
	background-color: #fafafa;
	border-radius: 50px;
	padding: 10px 20px;
	border: 0 solid #274a76
}

.btn-w2 {
	color: #274a76;
	font-weight: 700;
	background-color: #fafafa;
	border-radius: 50px;
	padding: 8px 30px;
	border: 3px solid #fff;
	-webkit-box-shadow: 0 0 0 1px #ccc;
	        box-shadow: 0 0 0 1px #ccc;
	display: inline-block
}

.btn-g {
	color: #fff;
	font-weight: 700;
	background-color: #44b319;
	border-radius: 50px;
	border: 2px solid #44b319;
	display: inline-block;
	padding: 8px 30px;
	font-size: 14px;
	min-width: 182px;
	text-align: center
}

.btn-g:hover {
	color: #44b319;
	background-color: transparent;
	border: 2px solid #44b319;
	-webkit-box-shadow: 0 0 0 0 hsla(97, 75%, 40%, .2);
	        box-shadow: 0 0 0 0 hsla(97, 75%, 40%, .2)
}

.btn-b {
	color: #fff!important;
	font-weight: 700;
	background-color: #000;
	border-radius: 50px;
	padding: 12px 30px;
	border: 3px solid #f6f7fa;
	-webkit-box-shadow: 0 0 0 1px #ccc;
	        box-shadow: 0 0 0 1px #ccc;
	cursor: pointer;
	outline: 0!important;
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s
}

.btn-b:enabled:hover {
	cursor: pointer;
	color: #44b319!important
}

.btn-w2 {
	color: #274a76;
	font-weight: 700;
	background-color: #fafafa;
	border-radius: 50px;
	padding: 12px 30px;
	border: 3px solid #fff;
	display: inline-block
}

.nav-menu,
.nav-menu * {
	margin: 0;
	padding: 0;
	list-style: none
}

.nav-menu ul {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 99
}

.nav-menu li {
	position: relative;
	white-space: nowrap
}

.nav-menu>li {
	display: inline-block;
	vertical-align: middle
}

.main-head-navigation ul.head-nav {
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: auto;
	padding: 0 0
}

.main-head-navigation ul.head-nav li {
	float: left
}

.main-head-navigation ul.head-nav li.menu-has-children+li:last-of-type {
	margin-right: 10px;
}

.head-nav-btn li a.head-btn {
	padding: 6px 24px;
	margin-left: 5px
}

.nav-menu li.sfHover>ul,
.nav-menu li:hover>ul {
	display: block
}

.nav-menu ul ul {
	top: 0;
	left: 100%
}

.nav-menu ul li {
	min-width: 180px
}

.nav-menu>li a.head-btn {
	margin-left: 10px
}

.sf-arrows .sf-with-ul {
	padding-right: 22px
}

.sf-arrows .sf-with-ul:after {
	content: "\f107";
	position: absolute;
	right: 8px;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400
}

.sf-arrows ul .sf-with-ul:after {
	content: "\f105"
}

#nav-menu-container {
	margin-left: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.nav-menu a {
	padding: 6px 12px;
	text-decoration: none;
	display: inline-block;
	color: #fff;
	font-weight: 400;
	font-size: 15px;
	outline: 0
}

.nav-menu a i {
	padding-left: 5px
}

.nav-menu .menu-active>a,
.nav-menu li:hover>a {
	color: #fff;
	background: #274a76;
	border-radius: 5px
}

.nav-menu .menu-btn>a {
	color: #fff;
	background-color: #274a76;
	border-radius: 50px;
	padding: 8px 30px
}

.nav-menu>li {
	margin-left: 0
}

.nav-menu ul {
	margin: 0;
	padding: 0;
	-webkit-box-shadow: 0 5px 5px rgba(127, 137, 161, .25);
	        box-shadow: 0 5px 5px rgba(127, 137, 161, .25);
	border: 1px solid #f2f2f2;
	background: #fff;
	border-radius: 5px;
	overflow: hidden
}

.nav-menu ul li {
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	border-bottom: 1px solid #f2f2f2
}

.nav-menu ul li:last-child {
	border: none
}

.nav-menu ul li a {
	padding: 6px 15px;
	color: #000;
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	display: block;
	font-size: 13px;
	text-transform: none
}

.nav-menu ul li:hover>a {
	background: #274a76;
	color: #fff
}

.nav-menu ul ul {
	margin: 0
}

#mobile-nav-toggle {
	position: fixed;
	right: 0;
	top: 0;
	z-index: 999;
	margin: 20px 20px 0 0;
	border: 0;
	background: 0 0;
	font-size: 24px;
	display: none;
	-webkit-transition: all .4s;
	-o-transition: all .4s;
	transition: all .4s;
	outline: 0;
	cursor: pointer
}

#mobile-nav-toggle i {
	color: #555
}

#mobile-nav {
	position: fixed;
	top: 0;
	padding-top: 18px;
	bottom: 0;
	z-index: 998;
	background: rgba(52, 59, 64, .9);
	left: -260px;
	width: 260px;
	overflow-y: auto;
	-webkit-transition: .4s;
	-o-transition: .4s;
	transition: .4s
}

#mobile-nav ul {
	padding: 0;
	margin: 0;
	list-style: none
}

#mobile-nav ul li {
	position: relative
}

#mobile-nav ul li a {
	color: #fff;
	font-size: 16px;
	overflow: hidden;
	padding: 10px 22px 10px 15px;
	position: relative;
	text-decoration: none;
	width: 100%;
	display: block;
	outline: 0
}

#mobile-nav ul li a:hover {
	color: #fff
}

#mobile-nav ul li li {
	padding-left: 30px
}

#mobile-nav ul .menu-has-children i {
	position: absolute;
	right: 0;
	z-index: 99;
	padding: 15px;
	cursor: pointer;
	color: #fff
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
	color: #50d8af
}

#mobile-nav ul .menu-item-active {
	color: #50d8af
}

#mobile-body-overly {
	width: 100%;
	height: 100%;
	z-index: 997;
	top: 0;
	left: 0;
	position: fixed;
	background: rgba(52, 59, 64, .9);
	display: none
}

body.mobile-nav-active {
	overflow: hidden
}

body.mobile-nav-active #mobile-nav {
	left: 0
}

body.mobile-nav-active #mobile-nav-toggle {
	color: #fff
}

.space80 {
	padding-top: 60px
}

.spaceB80 {
	padding-bottom: 80px
}

.feature {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-flow: column;
	        flex-flow: column;
	-ms-flex-direction: column;
	    flex-direction: column;
	height: 100%;
	padding: 40px 15px;
	text-align: center;
	color: #737373;
	background: 0 0;
	-webkit-transition: all .6s ease-in-out;
	-o-transition: all .6s ease-in-out;
	transition: all .6s ease-in-out
}

.feature img {
	width: 70px;
	margin: 0 auto
}

.feature h3 {
	padding-top: 14px;
	color: #000;
	font-size: 22px;
	font-weight: 400
}

.feature p {
	min-height: 130px
}

.hpb .feature p {
	line-height: 20px;
	padding: 15px 15px;
	color: #737373;
	font-size: 14px;
	height: 100%;
	padding-bottom: 0
}

.feature a {
	color: #274a76;
	font-weight: 700;
	background-color: #fafafa;
	margin: auto;
	margin-top: 0;
	margin-bottom: 0;
	width: 170px;
	border-radius: 50px;
	padding: 8px 30px;
	border: 3px solid #fff;
	-webkit-box-shadow: 0 0 0 1px #ccc;
	        box-shadow: 0 0 0 1px #ccc
}

.feature:hover a {
	color: #44b319;
	font-weight: 700;
	background-color: #fafafa;
	border-radius: 50px;
	padding: 8px 30px;
	border: 3px solid #274a76;
	-webkit-box-shadow: 0 0 0 1px #ccc;
	        box-shadow: 0 0 0 1px #ccc
}

.space-TB-80 {
	padding: 80px 0
}

.space-LR-20 {
	padding: 0 20px
}

.space-tb-10 {
	padding: 10px 0
}

.space-10 {
	padding: 10px
}

.space-TB-50 {
	padding: 50px 0
}

.grey-gradient {
	background: #fff;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(28%, #f6f7fa));
	background: -o-linear-gradient(top, #fff 0, #f6f7fa 28%);
	background: linear-gradient(to bottom, #fff 0, #f6f7fa 28%)
}

.workflow h2 {
	font-size: 40px;
	font-weight: 400
}

.workflow ul {
	font-size: 20px;
	font-weight: 300;
	color: #44b319;
	padding: 20px 0 0 0;
	margin: 0
}

.workflow ul li {
	background-image: url(../img/customBuilt.png);
	background-repeat: no-repeat;
	background-size: 16px 16px;
	padding: 0 0;
	font-size: 16px;
	list-style: none;
	padding-left: 22px;
	background-position-x: left;
	background-position-y: 5px
}

.workflow p {
	padding: 11px 0 50px 0;
	color: #737373;
	font-size: 14px
}

.brief {
	text-align: center
}

.brief h2 {
	font-size: 28px;
	font-weight: 400
}

.brief p {
	padding: 5px 0;
	font-size: 14px;
	color: #737373;
	margin-top: 10px
}

.positioning {
	position: relative
}

.positioning img {
	margin-top: 0;
	padding: 50px 0;
	width: 90%;
	margin: auto;
	display: block
}

.point {
	position: absolute;
	top: 10%;
	float: left
}

.positioning .point {
	position: absolute;
	top: 8%;
	float: left;
	left: 0
}

.point h3 {
	font-size: 18px;
	font-weight: 600;
	color: #284a77
}

.point p {
	font-size: 14px;
	width: 43%;
	padding: 10px 0;
	line-height: 22px
}

.point p::after {
	content: url(../img/pointer1.png)
}

.point2 {
	position: absolute;
	top: 64%;
	width: 38%;
	right: 0;
	text-align: right
}

.point2 h3 {
	font-size: 18px;
	font-weight: 600;
	color: #284a77
}

.point2 p {
	font-size: 14px;
	width: auto;
	padding: 10px 0;
	line-height: 22px;
	text-align: right
}

.point2 h3::before {
	content: url(../img/pointer2.png);
	position: absolute;
	right: 0;
	top: -25px
}

.point2 p:after {
	content: ''
}

.space100 {
	padding-top: 100px
}

.hpb h2 {
	font-size: 40px;
	font-weight: 400;
	padding-bottom: 30px
}

.hpb p {
	padding-bottom: 50px;
	font-size: 14px;
	color: #737373;
	line-height: 20px
}

#call-to-action {
	background: #274a76;
	background-size: cover;
	padding: 40px 0
}

#call-to-action .cta-title {
	color: #fff;
	font-size: 24px;
	font-weight: 700
}

#call-to-action .cta-text {
	color: #fff
}

.section-header {
	text-align: center
}

#enterprise img {
	max-width: 134px;
	opacity: 1;
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	padding: 15px 0
}

#enterprise img:hover {
	opacity: .6
}

#enterprise .owl-dots,
#enterprise .owl-nav {
	margin-top: 5px;
	text-align: center
}

#enterprise .owl-dot {
	display: inline-block;
	margin: 0 5px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #274a76
}

#enterprise .owl-dot.active {
	background-color: #77c461
}

.clients-carousel {
	padding-top: 20px
}

#integrations p {
	padding: 10px 0;
	font-size: 17px;
	color: #737373
}

.caps {
	text-transform: uppercase
}

#integrations a {
	color: #44b319;
	font-size: 17px
}

#integrations a:hover {
	color: #274a76
}

.section-heading h2 {
	padding: 10px 0;
	font-size: 40px;
	font-weight: 400
}

.section-heading p {
	padding: 10px 0;
	font-size: 14px;
	color: #737373;
	line-height: 20px
}

.section-heading img {
	margin-top: 113px
}

.spaceL40 {
	padding-left: 40px
}

.supporticon {
	float: left;
	position: relative;
	border-radius: 4px;
	background-color: #77c461;
	width: 60px;
	height: 60px
}

.supporticon img {
	width: 60%;
	padding-top: 22%
}

.supportcontent {
	margin-left: 80px;
	margin-bottom: 30px
}

.supportcontent h3 {
	font-size: 20px;
	font-weight: 400;
	color: #274a76
}

.supportcontent p {
	font-size: 13px;
	font-weight: 400;
	padding-top: 5px;
	line-height: 18px
}

.footer {
	background: #fff url(../img/footerBg.png) no-repeat bottom center;
	background-size: contain;
	border-top: 3px solid #274a76;
	padding-top: 60px;
	-webkit-box-shadow: -3px 4px 59px -8px rgba(0, 0, 0, .25);
	box-shadow: -3px 4px 59px -8px rgba(0, 0, 0, .25);
	position: relative;
	z-index: 999
}

.spaceT40 {
	padding-top: 40px
}

.social {
	text-align: right
}

.social a {
	padding: 0 10px;
	color: #adbdc1
}

.social a:hover {
	color: #274a76
}

.footer-widget {
	padding-bottom: 40px
}

.dynamic-link-wrap.footer-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between
}

.footer-title {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 15px
}

.dynamic-link-wrap .footer-title {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	        flex: 0 0 50%
}

.footer-widget ul {
	padding: 0
}

.dynamic-link-wrap ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	        flex: 0 0 100%;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap
}

.footer-widget ul li {
	list-style: none
}

.dynamic-link-wrap ul li {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	        flex: 0 0 50%
}

.footer-widget ul li a {
	font-size: 13px;
	color: #636e74;
	padding-bottom: 7px;
	display: block
}

.footer-widget ul li a:hover {
	color: #77c461
}

.newsletter-form {
	padding-top: 0;
	position: relative
}

.newsletter-form .form-control {
	background-color: #fff;
	border-color: #dde8ea;
	width: 82%;
	display: inline-block;
	color: #000
}

.newsletter-form .form-control:focus {
	color: #000
}

.newsletter-form input::-webkit-input-placeholder {
	color: #a3a3a3!important
}

.newsletter-form input:focus::-webkit-input-placeholder {
	color: #a3a3a3!important
}

.btn-front {
	background-color: #0e1a35;
	color: #fff;
	font-weight: 700;
	text-align: center;
	padding: 8px;
	margin-top: -3px;
	margin-left: -6px
}

.btn-front:hover {
	color: #77c461
}

.tiny-footer {
	font-size: 13px;
	padding: 14px 0;
	background-color: transparent;
	color: #888d90;
	line-height: 1
}

section#enterprise h2 {
	margin-bottom: 20px
}

.ft-logo {
	margin-left: -3px
}

a.copyrightlink {
	color: #f37d05
}

section#enterprise p {
	margin-bottom: 10px;
	font-size: 17px;
	color: #737373
}

section#integrations h2 {
	margin-bottom: 10px
}

.key_features_grid_wrap [class*=col-] {
	margin-bottom: 30px
}

.company-icons-white .img-heig {
	min-height: 70px
}

.key_features_grid_wrap .company-icons-white {
	padding: 30px 14px;
	height: 100%
}

.culture-section2.company-sections.space-TB-80 {
	padding-top: 0
}

.wrapper.document .feature {
	padding: 40px 20px
}

.contact-form {
	-webkit-box-shadow: 2px 2px 6px 0 rgba(224, 224, 224, .28);
	        box-shadow: 2px 2px 6px 0 rgba(224, 224, 224, .28);
	margin-top: 110px;
	margin-bottom: 5%;
	width: 70%;
	position: relative;
	padding: 30px 15px;
	border: 1px solid rgba(238, 238, 238, .52);
	border-radius: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.contact-form .brief {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	        flex: 0 0 50%;
	max-width: 50%;
	padding-top: 12%;
	padding-right: 15px
}

.contact-form .form-control {
	border-radius: 4px
}

.contact-form .btn-b {
	margin-top: 1rem
}

.contact-image {
	width: 122px;
	margin: 0 auto;
	margin-top: 20px
}

.contact-image img {
	border-radius: 6rem;
	-webkit-transform: rotate(29deg);
	-ms-transform: rotate(29deg);
	    transform: rotate(29deg);
	border: 1px solid #ccc
}

.contact-form form {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	        flex: 0 0 50%;
	padding-left: 15px
}

.contact-form h3 {
	margin-bottom: 30px;
	text-align: center;
	color: #0062cc;
	font-size: 25px
}

.contact-form .btnContact {
	width: 50%;
	border: none;
	border-radius: 1rem;
	padding: 1.5%;
	background: #dc3545;
	font-weight: 600;
	color: #fff;
	cursor: pointer
}

.btnContactSubmit {
	width: 50%;
	border-radius: 1rem;
	padding: 1.5%;
	color: #fff;
	background-color: #0062cc;
	border: none;
	cursor: pointer
}

.content-type .intro-type p,
.intro-type,
.separator-type,
.title-type {
	position: relative
}

.intro-type .container {
	padding: 107px 0 102px 0!important
}

#product-header-section,
.intro-type .container {
	padding: 68px 15px 72px!important
}

.parallax {
	background-attachment: inherit!important
}

.parallax {
	background-attachment: inherit!important;
	background-repeat: repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	z-index: -10
}

.red-border {
	width: 50%;
	border-bottom: 1px solid #d2282e;
	margin: 0 auto;
	height: 16px
}

.company-sections p {
	margin-top: 20px
}

.ct-u-size22 {
	font-size: 18px
}

.company-icons-white {
	padding: 30px 20px;
	background: rgba(255, 255, 255, .1);
	border-radius: 5px
}

.ct-u-fontWeight300 {
	font-weight: 300
}

.marginTop40 {
	margin-top: 40px!important
}

.ct-u-paddingBoth100 {
	padding: 100px 15px
}

.culture-section {
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .45)), to(rgba(0, 0, 0, .45))), url(../img/man-coffee-cup-pen.png) top no-repeat;
	background: -o-linear-gradient(rgba(0, 0, 0, .45), rgba(0, 0, 0, .45)), url(../img/man-coffee-cup-pen.png) top no-repeat;
	background: linear-gradient(rgba(0, 0, 0, .45), rgba(0, 0, 0, .45)), url(../img/man-coffee-cup-pen.png) top no-repeat;
	background: -o-linear-gradient(45deg, rgba(75, 19, 117, .5) 0, rgba(39, 74, 118, .75) 50%, rgba(116, 61, 255, 1) 100%), url(../img/man-coffee-cup-pen.png) top no-repeat;
	background: linear-gradient(45deg, rgba(75, 19, 117, .5) 0, rgba(39, 74, 118, .75) 50%, rgba(116, 61, 255, 1) 100%), url(../img/man-coffee-cup-pen.png) top no-repeat
}

.culture-section2 {
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 1)), to(rgba(255, 255, 255, 0))), url(../img/man-coffee-cup-pen.png) top no-repeat;
	background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%), url(../img/man-coffee-cup-pen.png) top no-repeat;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%), url(../img/man-coffee-cup-pen.png) top no-repeat;
	background-size: cover
}

.slick-slider {
	margin-bottom: 0
}

.slick-slider {
	position: relative;
	display: block;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent
}

.slick-slider .slick-list,
.slick-slider .slick-track {
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block
}

.slider .item {
	overflow: hidden
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px
}

.logos {
	margin-right: -5px;
	margin-left: -5px
}

ul.logos li {
	cursor: pointer
}

.logos>li {
	float: left;
	width: 16.66666%;
	padding: 5px
}

.logos .logos-inner {
	position: relative
}

.slick-slide img {
	display: inline-block
}

.logo-image {
	position: relative;
	width: 100%
}

ul.logos li {
	cursor: pointer
}

.logos>li {
	float: left;
	width: 16.66666%;
	padding: 5px
}

.logos .logos-inner {
	position: relative
}

.slick-slide img {
	display: inline-block
}

img {
	display: inline-block;
	max-width: 100%;
	vertical-align: middle
}

.btn-solodev-red-reversed {
	background-color: #fff;
	color: #d2282e;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
	padding: 12px 35px
}

.btn-blk {
	font-size: 18px!important
}

.ct-u-size19 {
	margin-top: 40px
}

section.clients-home .clients-logos .client-logos-repeater {
	min-height: 260px;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	cursor: pointer
}

section.clients-home .clients-logos .client-logos-repeater img {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	right: 0
}

.logo-title {
	position: absolute;
	display: none;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 16px;
	font-weight: 700;
	line-height: 16px;
	text-transform: uppercase;
	color: #fff;
	background-color: #0079c2
}

.ct-u-paddingBottom100 {
	margin-top: 40px
}

.culture-section {
	background-size: cover;
	color: #fff
}

.company-icons-white .fa {
	font-size: 30px;
	padding-bottom: 10px
}

.company-icons-white p {
	padding-bottom: 10px
}

.marginTop40 {
	margin-top: 40px
}

#tabs {
	background: #007b5e;
	color: #eee
}

#tabs h6.section-title {
	color: #eee
}

.nav-tabs .nav-link {
	cursor: pointer
}

#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #333;
	background-color: transparent;
	border-color: transparent transparent #f3f3f3;
	border-bottom: 2px solid;
	font-size: 18px;
	font-weight: 700;
	text-align: center
}

#tabs .nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem;
	color: #eee;
	font-size: 20px
}

#price {
	text-align: center
}

.plan {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin: 30px 2px
}

.plan-inner {
	background: #fff;
	margin: 0 auto;
	min-width: 270px;
	max-width: 100%;
	position: relative
}

.entry-title {
	background: #53cfe9;
	height: 140px;
	position: relative;
	text-align: center;
	color: #fff;
	margin-bottom: 30px
}

.entry-title>h3 {
	background: #20bada;
	font-size: 20px;
	padding: 5px 0;
	text-transform: uppercase;
	font-weight: 700;
	margin: 0
}

.entry-title .price {
	position: absolute;
	bottom: -25px;
	background: #20bada;
	height: 95px;
	width: 95px;
	margin: 0 auto;
	left: 0;
	right: 0;
	overflow: hidden;
	border-radius: 50px;
	border: 5px solid #fff;
	line-height: 80px;
	font-size: 24px;
	font-weight: 700
}

.price span {
	position: absolute;
	font-size: 9px;
	bottom: -10px;
	left: 30px;
	font-weight: 400
}

.entry-content {
	color: #323232
}

.entry-content ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center
}

.entry-content li {
	border-bottom: 1px solid #e5e5e5;
	padding: 10px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	text-align: left;
	-webkit-box-pack: left;
	    -ms-flex-pack: left;
	        justify-content: left;
	padding-left: 15px;
	font-size: 15px
}

.entry-content li .tick {
	margin-right: 10px
}

.entry-content li:last-child {
	border: none
}

.btnSpace {
	padding: 3em 0;
	text-align: center
}

.basic .entry-title {
	background: #75ddd9
}

.basic .entry-title>h3 {
	background: #44cbc6
}

.basic .price {
	background: #44cbc6
}

.standard .entry-title {
	background: #4484c1
}

.standard .entry-title>h3 {
	background: #3772aa
}

.standard .price {
	background: #3772aa
}

.ultimite .entry-title>h3 {
	background: #dd4b5e
}

.ultimite .entry-title {
	background: #f75c70
}

.ultimite .price {
	background: #dd4b5e
}

.packages h4 {
	padding-bottom: 10px
}

.ultimite h1 {
	padding-top: 20px
}

.space-T-20 {
	padding-top: 20px
}

.block-grid {
	display: block;
	padding: 0;
	margin: 0 -.625rem
}

ul.block-grid {
	padding: 0;
	margin: 0 auto
}

ul.block-grid li {
	list-style: none;
	display: inline-block
}

ul.block-grid li img {
	padding: 10px;
	min-height: 50px;
	max-width: 150px
}

.bg-heading {
	background: #f8f8f8;
	padding: 10px;
	margin: 0 -15px;
	overflow: hidden
}

.heading-custom-aligend h3 {
	padding-top: 9px
}

.left-navbar {
	border: none;
	border-radius: 0;
	margin-right: 15px
}

.navbar-btn {
	-webkit-box-shadow: none;
	        box-shadow: none;
	outline: 0!important;
	border: none
}

.line {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #ddd;
	margin: 40px 0
}

.wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch
}

#sidebar {
	min-width: 250px;
	max-width: 250px;
	background: #274a76;
	color: #fff;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s
}

#sidebar.active {
	margin-left: -250px
}

#sidebar .sidebar-header {
	padding: 20px;
	background: #6d7fcc
}

#sidebar ul.components {
	padding: 0;
	border-bottom: 1px solid #47748b
}

#sidebar ul.components li {
	padding: 0
}

#sidebar ul p {
	color: #fff;
	padding: 10px
}

#sidebar ul li {
	display: block
}

#sidebar ul li a {
	padding: 11px 10px;
	display: block;
	color: #fff
}

#sidebar ul li a:hover {
	color: #274a76;
	background: #fff;
	font-weight: 700
}

#sidebar ul li.active>a,
a[aria-expanded=true] {
	color: #fff;
	background: #6d7fcc
}

a[data-toggle=collapse] {
	position: relative
}

.dropdown-toggle::after {
	display: block;
	position: absolute;
	top: 50%;
	right: 20px;
	-webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	        transform: translateY(-50%)
}

ul.CTAs {
	padding: 20px
}

ul.CTAs a {
	text-align: center;
	font-size: .9em!important;
	display: block;
	border-radius: 5px;
	margin-bottom: 5px
}

a.download {
	background: #fff;
	color: #7386d5
}

a.article,
a.article:hover {
	background: #6d7fcc!important;
	color: #fff!important
}

#content {
	width: 100%;
	padding: 20px;
	min-height: 100vh;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s
}

.alert-danger {
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
	font-size: 13px;
	font-weight: 400;
	opacity: .8;
	padding: 5px 8px;
	border-radius: 0;
	margin-left: 2px
}

#login .alert-danger,
#login .alert-info {
	margin: 10px auto
}

#password .alert-danger {
	width: 100%
}

#left {
	background-color: #e4f3df;
	-webkit-box-shadow: -6px 0 10px #ccc inset;
	        box-shadow: -6px 0 10px #ccc inset;
	margin: 10px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center
}

#left a {
	display: block;
	max-width: 230px;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	        flex: 0 0 100%
}

#left a img {
	width: 160px
}

#right {
	background-color: #274a76;
	color: #fff;
	padding: 20px 15px
}

#email input:focus,
#firstname input:focus,
#lastname input:focus,
#password input:focus,
#repeatpassword input:focus,
#username input:focus {
	outline: 0;
	color: #000;
	border-bottom: 3px solid #44b319;
	-webkit-box-shadow: none;
	        box-shadow: none
}

#acct {
	margin-top: 15px;
	color: #fff
}

#signup-button {
	color: #fff;
	background-color: transparent;
	border: 2px solid #fff;
	border-radius: 5px;
	margin: auto;
	display: block;
	padding: 7px 0!important;
	width: 60%
}

#signup-button:hover {
	color: #fff;
	background-color: #44b319;
	border: 2px solid #44b319;
	border-radius: 5px
}

.loginbutton {
	padding: 4px 20px;
	margin-top: 15px;
	background-color: #44b319;
	border: 2px solid #44b319;
	border-radius: 5px;
	color: #fff;
	margin: auto
}

.loginbutton:hover {
	color: #44b319;
	background-color: transparent;
	border: 2px solid #44b319;
	border-radius: 4px
}

.loginbutton:focus {
	outline: 0
}

.text-arrange h2,
p {
	padding-bottom: 10px
}

.m-api-docs-table {
	font-weight: 400;
	border-spacing: 0;
	margin: 10px 0 20px;
	display: inline-block;
	max-width: 100%;
	border: 1px solid #e6e6e6
}

.m-api-docs-table tbody {
	letter-spacing: .05em;
	font-size: 15px;
	line-height: 1.7em
}

.m-api-docs-table tr>th {
	font-weight: 600;
	text-transform: uppercase;
	background: #f7f8f9;
	border-bottom: 1px solid #e6e6e6;
	padding: 10px 20px;
	text-align: left
}

.m-api-docs-table tr>td {
	padding: 10px 20px;
	text-align: left;
	border-bottom: 1px solid #e6e6e6;
	border-right: 1px solid #e6e6e6
}

.nav-tabs {
	border-bottom: none!important
}

#myTab {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center
}

#myTabContent {
	text-align: -webkit-center
}

.workflow input#email {
	border: 1px solid #999;
	border-radius: 3px;
	font-size: 14px;
	padding: 8px;
	width: 80%
}

.workflow input {
	border: 1px solid #999;
	border-radius: 3px;
	font-size: 14px;
	padding: 8px;
	margin-bottom: 10px
}

.header-logo-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1145px;
	padding-top: 64px;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center
}

section.cta {
	position: relative;
	padding: 250px 0;
	background-image: url(../img/bg-cta.jpg);
	background-position: center;
	background-size: cover
}

section.cta .cta-content {
	position: relative;
	z-index: 1
}

section.cta .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5)
}

.backgroundcolor {
	background: #0e2854 url(../img/footerBg.png) no-repeat bottom center;
    background-size: contain;
    align-items: center;
}

.hide-menu {
	display: none;
	-webkit-transition-delay: .5s;
	     -o-transition-delay: .5s;
	        transition-delay: .5s
}

li.navbar-right-area.dropdown.nav-item .img_wrap img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	   object-fit: cover
}

li.navbar-right-area.dropdown.nav-item a.nav-link {
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.profile-image input[type=file] {
	width: 100%
}

.newsletter-form .form-control:focus {
	color: #000;
	-webkit-box-shadow: none;
	        box-shadow: none
}

section.cta .cta-content h2 {
	font-size: 60px;
	margin-top: 0;
	margin-bottom: 25px;
	color: #fff
}

.btn-style-md {
	font-size: 16px;
	padding: 6px 34px;
	font-weight: 500;
	line-height: normal
}

.btn-style-sm {
	font-size: 15px;
	padding: 5px 28px;
	font-weight: 500;
	line-height: normal
}

.btn-round {
	border-radius: 50px
}

.input-group input.form-control {
	background: #fff;
	border: 1px solid #dcdcdc;
	-webkit-box-shadow: none;
	        box-shadow: none;
	outline: 0;
	height: 38px;
	font-size: 13px;
	font-weight: 400;
	color: #717171;
	letter-spacing: .3px;
	line-height: normal
}

.input-group .input-group-prepend .input-group-text {
	font-size: 16px;
	color: #274a75;
	background: #f1f1f1;
	border: 1px solid #dcdcdc;
	display: block;
	text-align: center;
	padding: 7px 6px;
	height: 40px
}

.heading-slogan h3 {
	font-weight: 600;
	font-size: 32px;
	color: #000
}

.heading-slogan p {
	font-size: 15px
}

.alert-info.custom-style {
	font-size: 14px;
	padding: 10px
}

.login-form-fields {
	margin: 15px auto;
	display: block
}

.filed-label {
	font-size: 12px;
	font-weight: 600;
	color: #333;
	text-align: left;
	text-transform: uppercase;
	margin-bottom: 2px
}

.login-right-side {
	background-image: url(../img/signup-bg.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background: #274a74
}

.height100 {
	height: auto;
	min-height: 100vh;
}

#nav-menu-container span.menu-icon {
	cursor: pointer
}

.mt-75 {
	margin-top: 75px
}

.custom-padding.modal-footer,
.custom-padding.modal-header {
	padding: .5rem 1rem
}

.select-template-modalbody {
	padding: .5rem 1rem;
	overflow: auto;
	max-height: 360px
}

.select-template-modalField {
	border-bottom: 3px solid #e9e7e7;
	padding: 5px 10px;
	background: #f6f4f4;
	border-radius: 10px 10px;
	cursor: pointer;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	margin-bottom: 5px
}

.select-template-modalField:hover {
	background: #cfcfcf
}

.select-template-modalField:nth-last-of-type(1) {
	margin-bottom: 0
}

.tick {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	background: gray;
	margin-right: 5px
}

.tick-success {
	background: #44b319;
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg)
}

.tick-error {
	background: #f44336;
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg)
}

.tick:after,
.tick:before {
	background: #fff;
	position: absolute;
	content: ' ';
	left: 30%;
	right: 30%;
	top: 50%;
	height: 2px;
	bottom: auto
}

.tick-success:before {
	width: 2px;
	height: auto;
	bottom: 25%;
	top: 20%;
	left: 55%;
	right: auto
}

.tick-success:after {
	height: 2px;
	bottom: 25%;
	left: 30%;
	right: 45%;
	top: auto
}

.tick-error:after {
	width: 2px;
	height: auto;
	top: 20%;
	bottom: 20%;
	left: 50%;
	right: auto;
	margin-left: -1px
}

.tick-error:before {
	left: 20%;
	right: 20%;
	top: 50%;
	bottom: auto;
	margin-top: -1px;
	height: 2px
}

.table-cover {
	background: #fafafa;
	border: none;
	-webkit-box-shadow: 0 0 4px 2px hsla(0, 0%, 66.7%, .1);
	        box-shadow: 0 0 4px 2px hsla(0, 0%, 66.7%, .1)
}

.table-cover table {
	width: 100%
}

.table-cover .table-hover tbody tr {
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.table-cover .table-hover tbody tr:hover {
	background: #f2f8ff
}

li.navbar-right-area.dropdown.nav-item a.nav-link[aria-expanded=true] {
	background: none;
}

@media (max-width:1200px) {
	#header .logo-size img {
		height: 45px
	}
	.nav-menu a {
		font-size: 14px
	}
}

@media (min-width:1200px) {
	#login {
		margin: 0;
		width: 100%
	}
	.headingsignin {
		margin-top: 15px!important;
		font-size: 2.25em
	}
}

@media (max-width:1199px) {
	.headingsignin {
		font-size: 30px
	}
}

@media (max-width:991px) {
	#login {
		margin: 0;
		width: 100%
	}
	.space80 {
		padding-top: 30px
	}
	.space-TB-80 {
		padding: 30px 0
	}
	.col-lg-5.col-md-12.offset-1 {
		margin-left: 0;
		margin-top: 15px
	}
	#nav-menu-container ul.nav-menu {
		display: none;
		right: 0;
		background: #fff;
		z-index: 99;
		top: 50px;
		position: absolute;
		width: 100%
	}
	#nav-menu-container ul.nav-menu li {
		width: 100%;
		text-align: left;
		border-bottom: 1px solid #eee
	}
	#nav-menu-container ul.nav-menu li:last-child {
		border-bottom: none
	}
	#nav-menu-container ul.head-nav.head-nav-btn li {
		border-bottom: none
	}
	header#header .container {
		position: relative
	}
	#nav-menu-container span.menu-icon {
		font-size: 25px;
		margin-top: 6px;
		display: inline-block;
		color: #fff;
		margin-right: 5px
	}
	.section-heading img {
		margin-top: 40px
	}
	#nav-menu-container ul.show.nav-menu {
		display: block;
		padding-bottom: 10px;
		border: 1px solid #e8e8e8
	}
	div#content .react-json-view {
		word-break: break-all
	}
	#nav-menu-container ul.nav-menu li ul {
		display: none
	}
	#nav-menu-container ul.nav-menu li.menu-has-children:active ul,
	#nav-menu-container ul.nav-menu li.menu-has-children:focus ul,
	#nav-menu-container ul.nav-menu li.menu-has-children:hover ul {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		    transform: translateY(0);
		-webkit-transition: trasnform .4s;
		-o-transition: trasnform .4s;
		transition: trasnform .4s;
		-webkit-transition: all .4s ease-in-out;
		-o-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out;
		visibility: hidden;
		opacity: 0;
		display: none;
		left: 10px
	}
	#nav-menu-container ul.nav-menu li.menu-has-children.active ul {
		visibility: visible;
		opacity: 1;
		display: block;
		left: 10px;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		    transform: translateY(0);
		-webkit-transition: trasnform .4s;
		-o-transition: trasnform .4s;
		transition: trasnform .4s;
		-webkit-transition: all .4s ease-in-out;
		-o-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out
	}
	.main-head-navigation ul.head-nav {
		width: 100%;
		padding: 0 0;
		border: none;
		border-radius: 0
	}
	.main-head-navigation ul.head-nav li {
		margin: 0;
		width: 100%!important;
		border: none;
		margin-bottom: 5px;
		padding: 0 10px
	}
	.main-head-navigation ul.head-nav li a {
		width: 100px
	}
	.main-head-navigation ul.head-nav li a.btn-blue {
		color: #fff
	}
	.homepage-bar-icon {
		width: 75%
	}
	#header .logo-size img {
		height: 45px
	}
	#mobile-nav-toggle {
		display: inline
	}
	.workflowimg img {
		margin-top: 30px
	}
	.workflow a {
		display: block;
		text-align: center
	}
	.point2 {
		width: 62%
	}
	#secure a {
		display: block;
		text-align: center;
		max-width: 200px;
		margin-top: 15px
	}
	#secure img {
		width: 80%;
		margin-top: 30px
	}
	.space100 {
		padding-top: 10px
	}
	.cta-btn-container {
		margin-top: 20px
	}
	.section-heading {
		text-align: center
	}
	.supportcontent {
		padding-bottom: 20px
	}
	#intro {
		height: 500px
	}
	#intro .circle {
		width: 80%;
		top: 3%
	}
	#intro img {
		right: 2%;
		height: 82%
	}
	.hpb p {
		padding-bottom: 0
	}
	#intro h1.display-1 {
		font-weight: 600;
		font-size: 40px;
		line-height: 40px
	}
	#intro .caption {
		padding-top: 5rem
	}
	.point {
		width: 100%;
		float: none;
		text-align: center
	}
	.point p {
		width: 100%!important
	}
	.point p::after {
		content: ''
	}
	.point2 h3::before {
		content: ''
	}
	.point2 {
		width: 100%;
		float: none;
		text-align: center
	}
	.point2 p {
		width: 100%!important
	}
	.story-section h2 {
		font-size: 40px;
		margin-bottom: 10px
	}
	.culture-section h2 {
		font-size: 40px;
		margin-bottom: 10px
	}
	.company-icons-white {
		padding: 30px 7px
	}
	.esignpanda_product2_wrap {
		margin-bottom: 15px
	}
	.head-nav.head-nav-btn.show-on-mobile {
		display: block;
		margin: 0;
		padding-left: 5px
	}
	.header-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	#nav-menu-container {
		margin-left: auto!important
	}
	.nav-menu a {
		color: #274a76;
		display: block
	}
	#left {
		background-color: #fff;
		-webkit-box-shadow: none;
		        box-shadow: none;
		margin: 0;
		padding-top: 15px
	}
	#right {
		padding: 0 15px 20px 15px
	}
	h1.headingsignin {
		margin: 20px 0 0 0;
		font-size: 24px
	}
	.brief h2 {
		font-size: 24px
	}
	.contact-form {
		width: auto
	}
}

@media (min-width:991px) {
	.main-head-navigation ul.head-nav {
		display: inline-block!important
	}
}

@media (min-width:991px) and (max-width:1200px) {
	#login {
		margin: 0;
		width: 100%
	}
	#password,
	#username {
		width: 90%;
		margin: auto
	}
}

@media (min-width:768px) {
	section.cta .cta-content h2 {
		font-size: 80px
	}
	#call-to-action .cta-btn-container {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end
	}
}

@media (max-width:767px) {
	#intro .intro-content h2 {
		font-size: 34px
	}
	#services .box .box {
		margin-bottom: 20px
	}
	#services .box .icon {
		float: none;
		text-align: center;
		padding-bottom: 15px
	}
	#services .box h4,
	#services .box p {
		margin-left: 0;
		text-align: center
	}
	#testimonials .testimonial-item {
		margin: 30px 10px
	}
	.header-logo-wrapper {
		padding-top: 24px;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		    justify-content: center;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		margin: 0 14px
	}
	.contact-form form {
		padding: 20px 15px 20px 15px;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%
	}
	.brief h2 {
		font-size: 20px;
		padding: 0 15px
	}
	.brief p {
		margin-top: 0
	}
	.contact-form {
		width: calc(100% - 30px)!important;
		margin-right: 15px;
		margin-left: 15px;
		margin-top: 100px;
		max-width: 100%;
		padding: 0;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap
	}
	.contact-form .brief {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
		max-width: 100%;
		padding-top: 25px;
		padding-right: 0
	}
	.contact-form form .row {
		margin-bottom: 0!important
	}
	.contact-form h3 {
		margin-bottom: 20px!important;
		margin-top: 0!important;
		font-size: 18px
	}
	.brief.space100 {
		padding-top: 30px
	}
	.workflow h2 {
		font-size: 24px
	}
	.hpb h2 {
		font-size: 24px
	}
	.section-heading h2 {
		font-size: 24px
	}
	#sidebar {
		margin-left: 0;
		display: none
	}
	#sidebar.active {
		margin-left: 0
	}
	#sidebarCollapse span {
		display: none
	}
	.hide-menu {
		display: block!important
	}
	.footer {
		padding-top: 30px
	}
	.footer .ft-logo {
		text-align: left;
		margin-bottom: 10px
	}
	.footer .social {
		text-align: left
	}
	.spaceT40 {
		padding-top: 20px
	}
	.feature {
		padding: 25px 20px;
		text-align: center;
		min-height: auto;
		color: #737373
	}
	section.cta .cta-content h2 {
		font-size: 30px
	}
	section.cta {
		position: relative;
		padding: 50px 0
	}
	.row.space-LR-20.text-arrange.mt-20 {
		word-break: break-all
	}
	.row.space-LR-20.text-arrange.over {
		overflow: scroll
	}
	#signup-button {
		width: 80%
	}
	#intro {
		height: auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		    -ms-flex-direction: column-reverse;
		        flex-direction: column-reverse;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		background-position: center
	}
	#intro .circle {
		text-align: center
	}
	#intro h1.display-1 {
		font-size: 30px
	}
	#intro .caption {
		padding-top: 1rem;
		margin-bottom: 30px
	}
	#intro img {
		position: static;
		height: auto;
		width: 60%
	}
	#header .logo-size img {
		height: 45px
	}
	#mobile-nav-toggle {
		display: inline
	}
	#about .about-img {
		height: auto
	}
	#about .about-img img {
		margin-left: 0;
		padding-bottom: 30px
	}
	.point {
		width: 100%;
		float: none;
		text-align: center
	}
	.point p {
		width: 100%!important
	}
	.point p::after {
		content: ''
	}
	.point2 h3::before {
		content: ''
	}
	.point2 {
		width: 100%;
		float: none;
		text-align: center
	}
	.point2 p {
		width: 100%!important
	}
	.positioning img {
		opacity: .3
	}
	.owl-item.active {
		width: 180px!important
	}
	.owl-item.cloned {
		width: 180px!important
	}
	.owl-carousel .owl-stage-outer {
		height: 170px
	}
	section.clients-home .clients-logos .client-logos-repeater {
		border-right: 0!important;
		min-height: 170px!important
	}
	h2 {
		font-size: 24px;
		font-weight: 300
	}
	.company-icons-white {
		padding: 30px 20px;
		margin-bottom: 15px
	}
	.story-section h2 {
		font-size: 24px
	}
	.culture-section h2 {
		font-size: 24px
	}
	.key_features_grid_wrap [class*=col-] {
		margin-bottom: 15px
	}
	section.culture-section2.company-sections.space-TB-80 div#home .plan {
		margin-bottom: 0
	}
	.story-section.space-TB-50 {
		padding: 30px 0
	}
	.packages h4 {
		font-size: 20px
	}
	.contact-image {
		width: 60px;
		margin-top: 10px
	}
	.head-nav-btn li a.head-btn,
	.head-nav.head-nav-btn a.ouline-btn {
		padding: 4px 12px;
		font-size: 14px;
		display: inline-block
	}
	#intro .caption:before {
		display: none
	}
	#header {
		padding: 10px 0;
		border-bottom: 1px solid rgba(255, 255, 255, .36)
	}
	#left a img {
		width: 120px
	}
	h1.headingsignin {
		margin: 10px 0 0 0;
		font-size: 18px
	}
	.login-form-field .loginbutton {
		margin: 15px auto 0 auto;
		font-size: 14px
	}
	#left {
		padding-top: 10px
	}
	#right {
		padding: 0 15px 15px 15px
	}
	.btn-b {
		padding: 6px 25px;
		font-size: 14px
	}
}

@media (max-width:450px) {
	.positioning img {
		margin-top: 0;
		padding: 80px 0
	}
	.point2 {
		position: absolute;
		top: 49%
	}
	#intro .caption a.btn-w {
		margin-right: 5px
	}
	.btn-g {
		padding: 5px 20px;
		min-width: auto
	}
	.btn-w {
		padding: 8px 15px
	}
	.login-form-field .social-links {
		display: block;
		text-align: center;
		margin-top: 20px
	}
	.login-form-field .social-links ul {
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		margin-top: 20px
	}
	.login-form-field .social-links .loginbutton {
		margin: 0 auto
	}
	.login-form-field .social-links:after {
		display: none
	}
}

@media (max-width:575px) {
	.space80 {
		padding-top: 20px
	}
	.positioning .point {
		position: absolute;
		top: 2%;
		float: left;
		left: 0
	}
	.point2 {
		position: absolute;
		top: 64%
	}
	.footer-widget {
		padding-bottom: 10px;
		text-align: center
	}
	.footer-title {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 10px
	}
	.tiny-footer p {
		line-height: 20px
	}
	.feature {
		padding: 25px 20px;
		text-align: center;
		color: #737373
	}
	.section-heading img {
		margin-top: 40px
	}
	.marginTop40 {
		margin-top: 20px!important
	}
	.ct-u-size22 {
		font-size: 18px
	}
	.footer .ft-logo {
		text-align: center
	}
	.footer .social {
		text-align: center
	}
	.login-form-field {
		padding: 0
	}
}