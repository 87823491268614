	/*
  	Flaticon icon font: Flaticon
  	Creation date: 05/08/2019 13:13
  	*/

@font-face {
  font-family: "Flaticon3";
  src: url("./Flaticon3.eot");
  src: url("./Flaticon3.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon3.woff2") format("woff2"),
       url("./Flaticon3.woff") format("woff"),
       url("./Flaticon3.ttf") format("truetype"),
       url("./Flaticon3.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon3";
    src: url("./Flaticon3.svg#Flaticon") format("svg");
  }
}

[class^="fa-flaticon3-"]:before, [class*=" fa-flaticon3-"]:before,
[class^="fa-flaticon3-"]:after, [class*=" fa-flaticon3-"]:after {   
  font-family: Flaticon3;
        font-size: 30px;
font-style: normal;
}

.fa-flaticon3-hand:before { content: "\f100"; }
.fa-flaticon3-contract:before { content: "\f101"; }
.fa-flaticon3-home:before { content: "\f102"; }
.fa-flaticon3-folder:before { content: "\f103"; }
.fa-flaticon3-api:before { content: "\f104"; }
.fa-flaticon3-analytics:before { content: "\f105"; }
.fa-flaticon3-controls:before { content: "\f106"; }
.fa-flaticon3-one-finger-tap-gesture-of-outlined-hand-symbol:before { content: "\f107"; }
.fa-flaticon3-contact:before { content: "\f108"; }
.fa-flaticon3-users:before { content: "\f109"; }
.fa-flaticon3-package:before { content: "\f10a"; }