body {
	padding: 0;
	font-family: 'Nunito Sans', sans-serif!important
}

.inlineLoaderGif {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	right: 0
}

.inlineLoaderGif img {
	height: auto
}

.inlineLoaderGif img {
	height: 90px
}

.custom {
	width: 163px;
	margin: auto;
	width: 14%;
	margin: auto
}

.react-confirm-alert-body {
	text-align: center!important;
	margin: auto
}

.react-confirm-alert-body .react-confirm-alert-button-group {
	text-align: center;
	display: block
}

.index {
	z-index: 1
}

.header-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.head-nav.head-nav-btn {
	margin: 0;
	padding-left: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center
}

.head-nav.head-nav-btn a.ouline-btn {
	color: #fff;
	background: 0 0;
	border: 1px solid transparent;
	border-radius: 30px;
	padding: 6px 24px
}

.head-nav.head-nav-btn a.ouline-btn:focus,
.head-nav.head-nav-btn a.ouline-btn:hover {
	color: #fff;
	background: #274a76;
	border: 1px solid #274a76;
	border-radius: 30px
}

.custom-checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: normal;
	margin: 0 0;
	padding: 0 0 0 28px
}

.custom-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 18px;
	width: 18px;
	background-color: #f0f1f1;
	border: 1px solid #dbdbdb;
	border-radius: 4px
}

.custom-checkbox:hover input~.checkmark {
	background-color: #fff
}

.custom-checkbox input:checked~.checkmark {
	background-color: #2c4f7b
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none
}

.custom-checkbox input:checked~.checkmark:after {
	display: block
}

.custom-checkbox .checkmark:after {
	left: 5px;
	top: 2px;
	width: 6px;
	height: 10px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	    transform: rotate(45deg)
}

.documentAll_tbl .table td,
.documentCanceled_tbl .table td,
.documentSigned_tbl .table td,
.documentUnsigned_tbl .table td,
.table th {
	vertical-align: middle
}

.newsletter-form input.form-control {
	width: 100%!important;
	height: 42px;
	padding-right: 60px
}

.newsletter-btn {
	position: absolute;
	right: 0;
	top: 0
}

.newsletter-btn button.btn-front {
	border: none;
	padding: 11px 15px 10px 15px;
	margin: 0 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.alert-danger.bluenote {
	color: #000;
	background-color: #dae8f9;
	border-color: #274a76;
	border-left: 3px solid #274a76;
	font-size: 16px;
	padding: 25px
}

.mt-20 {
	margin-top: 20px
}

.mt-15 {
	margin-top: 15px
}

.mt-10 {
	margin-top: 10px
}

.mt-5 {
	margin-top: 5px
}

.mb-20 {
	margin-bottom: 20px
}

.mb-15 {
	margin-bottom: 15px
}

.mb-10 {
	margin-bottom: 10px
}

.mb-5 {
	margin-bottom: 5px
}

.border-box {
	border: 1px solid #e0e0e0;
	border-radius: 6px;
	padding: 20px
}

.jump-links-heading {
	color: #777;
	font-size: 17px;
	letter-spacing: -.025em;
	font-style: italic;
	font-weight: 600;
	margin-bottom: 15px
}

.jump-links ul {
	padding: 0;
	margin: 0;
	margin-left: 0;
	width: inherit;
	-moz-column-count: 3;
	-moz-column-gap: 70px;
	-webkit-column-count: 3;
	-webkit-column-gap: 70px;
	column-count: 3;
	column-gap: 70px
}

.jump-links ul>li {
	display: block;
	padding-bottom: 1em;
	padding-left: 0;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	-moz-column-break-inside: avoid;
	     break-inside: avoid;
	margin: 0
}

.article pre {
	background-color: #f2f2f2;
	margin-bottom: 10px;
	border-radius: 6px;
	font-size: 15px;
	color: #484848;
	white-space: pre-wrap;
	padding: 12px 15px;
	width: 80%
}

.article pre .pre-head {
	font-size: 16px;
	color: #484848;
	font-weight: 600;
	margin-bottom: 10px
}

.definition>ul {
	margin-left: 0;
	list-style: disc
}

.definition>ul li {
	margin-bottom: 1em;
	padding-left: 10px;
	line-height: 1.35em
}

.definition>ul li strong {
	display: block;
	margin-top: 1.2em
}

.displayNone {
	display: none!important
}

.search-container {
	position: relative;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto
}

.searchbar-group-dropdown {
	right: 0;
	top: 56px
}

.col-md-12.margin-bottom {
	margin-bottom: 20px
}

.badge,
button {
	cursor: pointer
}

.new-feature {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin-bottom: 10px
}

.new-feature input {
	float: left;
	width: calc(50% - 30px)!important;
	margin-right: 10px
}
.new-feature input.signingrole-field{
	float: none;
	width: 100%!important;
}
.new-feature span {
	margin-left: 0;
	cursor: pointer;
	border-radius: 50%;
	height: 25px;
	max-width: 25px;
	width: 25px;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 25px;
	        flex: 0 0 25px;
	border: 1px solid #4e4e4e;
	color: #4e4e4e;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s
}

.new-feature span:hover {
	color: #721c24;
	background: #f8d7da;
	border: 1px solid #f5c6cb
}

button.btn.theme_btn.add {
	width: auto!important;
	padding: 5px 15px!important;
	border-radius: 4px
}

.clear15.ss {
	width: 100%
}

.alert.alert-danger.ss {
	max-width: 500px;
	margin-left: 16px
}

.outer-dic-msg {
	width: 100%
}

section.culture-section2.company-sections.space-TB-80 div#home {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap
}
.ribbon-wrap {
    width: 100%;
    height: 138px;
    position: absolute;
    top: -8px;
    left: -8px;
    overflow: hidden;
}
.ribbon-wrap:before {
    width: 40px;
    height: 8px;
    left: 51px;
    background: #4D6530;
    border-radius: 8px 8px 0px 0px;
    content: "";
    position: absolute;
}
.ribbon-wrap:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 40px;
    left: 0;
    top: 87px;
    background: #4D6530;
    border-radius: 8px 0 0 8px;
}
.ribbon-wrap .ribbon {
    width: 170px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 26px;
    left: -50px;
    z-index: 2;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(-55deg);
        transform: rotate(-55deg);
    border: 1px dashed;
    -webkit-box-shadow: 0 0 0 3px #44b319, 0px 21px 5px -18px rgba(0,0,0,0.6);
            box-shadow: 0 0 0 3px #44b319, 0px 21px 5px -18px rgba(0,0,0,0.6);
    background: #44b319;
    text-align: center;
}
section.culture-section2.company-sections.space-TB-80 div#home .plan {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin: 30px 2px;
	-webkit-box-flex: 2;
	    -ms-flex: 2;
	        flex: 2
}

.search-container select#selectStatus {
	padding-right: 5px
}

.truncate-2line {
	display: block;
	display: -webkit-box;
	max-width: 100%;
	height: 43px;
	margin: 0 auto;
	padding: 0;
	-webkit-line-clamp: 2;
	line-height: 20px;
	-webkit-box-orient: vertical!important;
	box-orient: vertical;
	overflow: hidden;
	cursor: pointer;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis
}

p.truncate-2line:hover {
	color: #2c4f7b
}

.esignModal-body.padding.modal-body {
	padding: 10px 20px;
	max-height: 300px;
	overflow-y: scroll
}

.modal-header {
	background: #2c4f7b;
	color: #fff;
	border-radius: 0!important
}

.modal-title span {
	float: left;
	font-weight: 700
}

.modal-title .fa-close {
	cursor: pointer
}

span.tooltip.info-role {
	position: relative;
	display: inline-block
}

span.tooltip.info-role .tooltiptext {
	visibility: hidden;
	width: 180px;
	background-color: #2c4f7b;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	font-size: 16px;
	padding: 10px 0;
	position: absolute;
	z-index: 1;
	bottom: 30px;
	right: -70px
}

span.tooltip.info-role {
	position: relative;
	opacity: 1;
	z-index: 0;
	display: unset;
	font-size: 20px;
	line-height: 20px;
	margin-left: 5px
}

span.tooltip.info-role i.fa.fa-info-circle {
	vertical-align: text-bottom;
	color: #2c4f7b
}

span.tooltip.info-role:hover .tooltiptext {
	visibility: visible
}

span.file-extension.word {
	word-break: break-all
}

.esignModal-body.modal-body .esignModal-head span {
	text-transform: capitalize
}

.esignModal-footer.modal-footer button {
	text-transform: capitalize
}

.package-detail {
	padding: 0;
	margin: 0;
	list-style: none;
	border: 1px solid #eee;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px
}

.package-detail li {
	padding: 10px 15px;
	border-bottom: 1px solid #eee;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between
}

.package-detail li:last-child {
	border-bottom: 0
}

.package-detail li span {
	text-transform: capitalize
}

.package-payment-detail-modal .esignModal-body.modal-body {
	padding: 20px 10px
}

.package-payment-detail-modal .esignModal-body.modal-body .modal-title {
	margin-bottom: 20px
}

.StripeElement {
	display: block;
	width: 100%;
	padding: .601rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	-webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
	-o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

.secure-line {
	color: #44b319;
	margin-bottom: 10px
}

.btn-upgrade {
	background: #44b319;
	color: #fff
}

.package-detail-info-inner {
	border: 1px solid #eee;
	border-radius: 4px;
	background: rgba(238, 238, 238, .37)
}

.package-detail-info-inner .form-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding: 10px 14px;
	margin: 0;
	border-bottom: 1px solid #eee
}

.package-detail-info-inner .form-group i {
	margin-right: 10px;
	color: #44b319
}

.package-detail-info-inner .form-group label {
	margin: 0;
	font-weight: 500;
	padding-right: 5px
}

.package-detail-info-inner .form-group span {
	color: #2c4f7b;
	font-size: 16px;
	margin-left: auto
}

.create-api-form .title {
	margin-bottom: 15px
}

.paypal-payment {
	padding-top: 36px
}

.paypal-payment button.paypal_btn {
	padding: 0;
	border: none;
	background: 0 0
}
.paypal-payment button+button{
	margin-top: 10px;
}
.paypal-payment button img {
	outline: 0
}

.paypal-payment span {
	display: none
}

.stripe-paypal {
	margin-top: 36px;
	margin-bottom: 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between
}

.stripe-paypal .stripe-logo {
	width: 100px
}

.stripe-paypal span {
	font-size: 25px;
	line-height: normal;
	color: #000;
	font-weight: 600;
	text-align: left;
	display: block;
	margin: auto;
	-webkit-transform: translateX(20px);
	    -ms-transform: translateX(20px);
	        transform: translateX(20px);
	text-transform: uppercase
}

.verify-email-card-wrap .img-wrap {
	text-align: center;
	margin: 60px 0
}

.verify-email-card-wrap .img-wrap a {
	display: inline-block
}

.verify-email-card {
	max-width: 600px;
	background: #fbfbfb;
	border: 1px solid #f1f1f1;
	border-radius: 5px;
	padding: 30px 20px 40px 20px;
	margin: 0 auto;
	text-align: center
}

.verify-email-card i {
	font-size: 80px;
	color: #44b319
}

.verify-email-card i.fa-exclamation-circle {
	color: #f44336
}

.verify-email-card h1 {
	font-size: 34px;
	color: #274a76;
	margin: 20px 0 30px 0
}

.verify-email-card button {
	background-color: #44b319;
	border-radius: 50px;
	color: #fff;
	font-weight: 700;
	padding: 8px 30px;
	-webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #44b319;
	        box-shadow: 0 0 0 3px #fff, 0 0 0 4px #44b319;
	border: none;
	text-transform: uppercase;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	outline: 0
}

.verify-email-card button:hover {
	background-color: #fff;
	color: #44b319
}

.users-btn-wrap .theme-btn {
	margin: 0 15px
}

.flex-parent .users-btn-wrap .theme-btn {
	margin: 0
}

.theme-btn {
	background: #2c4f7b;
	color: #fff
}

.theme-green-btn {
	background: #44b319;
	color: #fff
}

#type-it-in {
	padding: 0 15px
}

#type-it-in input {
	width: 100%;
	height: 100px;
	font-size: 55px;
	text-align: center;
	border: 2px solid #e9ecef;
	color: #a3a3a3;
	line-height: normal
}

#type-it-in select {
	border: 2px solid #dbdddf;
	padding: 10px;
	float: left;
	margin-top: 20px
}

#upload-image {
	text-align: left;
	padding: 0 40px
}

#upload-image p:first-child {
	margin-bottom: 10px;
	padding-bottom: 10px;
	font-size: 16px
}

#upload-image p {
	font-size: 12px;
	padding-bottom: 5px
}

#upload-image input[type=file] {
	display: none
}

.file-uploader {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 5px 8px;
	cursor: pointer;
	margin-bottom: 10px;
	font-size: 14px
}

#saved-signature .carousel .slide {
	background: 0 0
}

#saved-signature .carousel .thumbs-wrapper {
	margin: 0
}

#saved-signature .carousel .control-next.control-arrow:before {
	border-left: 8px solid #020202
}

#saved-signature .carousel .control-prev.control-arrow:before {
	border-right: 8px solid #020202
}

#saved-signature .img-wrap {
	width: 260px;
	margin: 0 auto;
	height: 240px;
	position: relative
}

.deleteSignature {
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%)
}

#use-smart-phone {
	text-align: left;
	padding: 0 40px
}

#use-smart-phone ul {
	list-style: none;
	text-align: left;
	padding: 0;
	margin: 0;
	width: 350px
}

#use-smart-phone ul li {
	position: relative
}

#use-smart-phone ul li p {
	font-size: 14px
}

#use-smart-phone ul li p b {
	position: absolute;
	left: 200px
}

.brandimg-wrap {
	max-width: 250px;
	margin-bottom: 15px
}

.brandimg-wrap .img-wrap {
	position: relative;
	padding-bottom: 75%
}

.brandimg-wrap .img-wrap img {
	height: 100%;
	width: 100%;
	-o-object-fit: contain;
	   object-fit: contain;
	position: absolute
}

.brand-image-uploader {
	display: none
}

.profile-image-wrap {
	text-align: center
}

.profile-image-wrap input[type=file] {
	display: none
}

.my-profile-form .file-uploader {
	margin: 20px 0 40px 0
}

.profile-image {
	max-width: 260px
}

.profile-image .img-wrap {
	width: 100%;
	padding-bottom: 100%;
	position: relative;
	overflow: hidden
}

.profile-image img {
	border-radius: 50%;
	height: 100%;
	width: 100%;
	-o-object-fit: cover;
	   object-fit: cover;
	position: absolute;
	left: 0
}

.my-profile-form h3.page_title {
	margin: 10px 0 20px 0;
	font-size: 20px;
	font-weight: 600;
	padding-bottom: 5px
}

/* .my-profile-form select.form-control:not([size]):not([multiple]) {
	height: calc(2.25rem + 12px)
} */

.my-profile-form .change-password h5 {
	margin-bottom: 10px
}

.my-profile-form .change-password p {
	margin-bottom: 15px
}

.update-profile-btn-wrap {
	margin: 0 0 20px 0
}

.send-email-btn {
	width: 40%;
	margin: 0 auto;
	position: relative
}

.send-email-btn .loginbutton {
	width: 100%
}

.send-email-btn img {
	position: absolute;
	right: 0;
	top: -1px
}

.email-error-message {
	text-align: center;
	margin-top: 10px;
	color: #ff4242
}

.email-error-message p {
	text-transform: capitalize
}

.panel {
	border-radius: 3px;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin: 15px 0;
	border: 1px solid #d1d6e6;
	background: #fff
}

.panel .panel-heading {
	padding: 5px 10px;
	position: relative;
	line-height: 1;
	background-color: #f8f9fa;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	min-height: 49px
}

.panel .panel-heading .panel-title {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	color: #717d84
}

.package-widget {
	border-radius: 3px;
	padding: 10px 10px;
	margin-bottom: 15px;
	border: 1px solid #d1d6e6;
	background: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	text-align: center
}

.package-widget .icon-wrapper {
	border-radius: 50%;
	display: block;
	position: relative;
	width: 50px;
	height: 50px;
	text-align: center;
	color: #fff;
	overflow: hidden
}

.package-widget .right .heading {
	display: block;
	font-size: 14px;
	margin: 10px 0 0 0;
	color: #a0aeba
}

.package-widget .right .detial {
	display: block;
	font-size: 18px;
	color: #2c4f7b
}

.package-widget .right .detial.monthly {
	text-transform: uppercase
}

.panel-body {
	padding: 10px 15px
}

.panel-body .row .col {
	padding: 0 10px
}

.bg-red {
	background: #ff4402
}

.bg-lightseagreen {
	background: #20b2aa
}

.bg-blue2 {
	background: #4a90e2
}

.bg-purple {
	background: #ab7df6
}

.bg-orange {
	background: #f8a00e
}

.bg-skyblue {
	background: #14b1d0
}

.theme-bgclr {
	background: #2c4f7b
}

.esignModal-body.modal-body .modal-body-inner {
	padding: 15px 20px;
	width: 100%
}

.templateName-with-id {
	padding: 10px;
	border: 1px solid #eee;
	border-radius: 4px;
	background: rgba(238, 238, 238, .37);
	margin-bottom: 10px
}

.esignModal-body.modal-body .modal-body-inner .templateName-with-id:last-child {
	margin-bottom: 0
}

.templateName-with-id .checkmark {
	top: 5px
}

.temp-name {
	margin-left: 30px;
	font-size: 18px;
	color: #2c4f7b
}

.temp-name span {
	font-size: 14px;
	color: #000
}

.downgradeYourPackage-title p {
	padding-bottom: 0;
	text-align: center
}

.flex-parent {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	margin-bottom: 15px
}

.right-body-content .flex-parent h3.page_title {
	margin-top: 0;
	margin-bottom: 0;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto
}

.useTemplate_tbl table tbody tr td:last-child span i {
	font-size: 20px;
	color: #2c4f7b;
	margin-right: 5px
}

.useTemplate_tbl table tbody tr td:last-child .table-buttons span.custom-tooltip i {
	font-size: 15px;
	color: #fff;
	margin-right: 0
}

.useTemplate_tbl table tbody tr td:last-child span i.fa.fa-unlock-alt {
	color: #44b319
}

.useTemplate_tbl table tbody tr td {
	position: relative
}

.useTemplate_tbl table tbody tr.disable td:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, .77);
	cursor: no-drop;
	z-index: 9
}

.useTemplate_tbl table tbody tr.disable td {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.auto-payment {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-top: 15px
}

.auto-payment h3 {
	width: 100%
}

.thankyou-page-inner {
	max-width: 900px;
	margin: 0 auto;
	-webkit-transform: translateY(20px);
	    -ms-transform: translateY(20px);
	        transform: translateY(20px)
}

.thankyou-page-inner .head-area {
	text-align: center;
	background: #274a76;
	color: #fff;
	padding: 30px 0 20px 0;
	border-radius: 10px 10px 0 0
}

.thankyou-page-inner .head-area h3 {
	font-size: 24px;
	margin: 20px 0 10px 0;
	font-weight: 500
}

.thankyou-page-inner .body-area {
	padding: 50px 0;
	text-align: center;
	background: #f7f7f7;
	border-radius: 0 0 10px 10px
}

.thankyou-page-inner .body-area a.learn-more {
	display: inline-block;
	padding: 8px 30px;
	background: #274a76;
	color: #fff;
	margin-top: 15px;
	border: 1px solid transparent
}

.sign-request-close {
	text-align: center;
	padding: 50px 0
}

.sign-request-close .file-svg+h3 {
	font-weight: 300;
	color: #000;
	font-size: 24px;
	margin: 20px 0 10px 0
}

.sign-request-close .likely-reason {
	max-width: 530px;
	margin: 20px auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	position: relative;
	padding-top: 20px
}

.sign-request-close .likely-reason:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 1px;
	width: 80%;
	margin: 0 auto;
	background: #e7e7e7
}

.sign-request-close .likely-reason h2 {
	font-size: 16px;
	text-transform: uppercase;
	margin-top: 5px;
	font-weight: 600;
	-webkit-box-flex: 0;
	    -ms-flex-positive: 0;
	        flex-grow: 0;
	-ms-flex-negative: 0;
	    flex-shrink: 0;
	-ms-flex-preferred-size: 35%;
	    flex-basis: 35%
}

.sign-request-close .likely-reason ul {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: left
}

.sign-request-close .likely-reason ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin-bottom: 5px
}

.sign-request-close .likely-reason ul li i {
	margin-right: 10px;
	font-size: 12px
}

.sign-request-close .likely-reason ul li span {
	font-size: 14px
}

.add-flex .modal-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	width: 100%
}

.modal-title {
	width: 100%
}

.add-flex .modal-title div+div {
	cursor: pointer
}

.custom-checkbox [type=checkbox]:checked,
.custom-checkbox [type=checkbox]:not(:checked) {
	position: absolute;
	left: -9999px
}

.custom-checkbox [type=checkbox]:checked+label,
.custom-checkbox [type=checkbox]:not(:checked)+label {
	position: relative;
	padding-left: 1.95em;
	cursor: pointer;
	font-size: 14px;
	margin: 0
}

.custom-checkbox [type=checkbox]:checked+label:before,
.custom-checkbox [type=checkbox]:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 2px;
	width: 1.25em;
	height: 1.25em;
	border: 1px solid #d2d2d2;
	background: #fff;
	border-radius: 3px
}

.custom-checkbox [type=checkbox]:checked+label:after {
	border-style: solid;
	border-width: 0 2px 2px 0;
	content: '';
	height: 11px;
	left: 6px;
	position: absolute;
	top: 4px;
	-webkit-transform: rotate(40deg);
	-ms-transform: rotate(40deg);
	transform: rotate(40deg);
	width: 6px;
	border-color: #fff
}

.custom-checkbox [type=checkbox]:checked+label:before {
	background: #1c629e
}

.custom-checkbox [type=checkbox]:checked+label:after {
	opacity: 1
}

.head-nav.head-nav-btn.show-on-mobile {
	display: none
}

.detail-about-sign-modal-body {
	max-height: 350px;
	overflow: auto
}

.detail-about-sign {
	list-style: none;
	padding: 0;
	margin: 0
}

.detail-about-sign>li:nth-of-type(1) {
	padding-top: 0
}

.detail-about-sign li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 10px 0;
	border-bottom: 1px solid #e3e3e3;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between
}

.detail-about-sign li:last-child {
	border: none
}

.detail-about-sign li span {
	color: #959595;
	text-transform: uppercase;
	font-size: 14px;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 120px;
	        flex: 0 0 120px
}

.detail-about-sign li:first-child span {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 110px;
	        flex: 0 0 110px
}

.detail-about-sign li:last-child span {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 80px;
	        flex: 0 0 80px
}

.detail-about-sign li span.badge {
	color: #fff
}

.detail-about-sign li p {
	padding-bottom: 0;
	margin-bottom: 0;
	color: #000;
	text-align: right;
	word-break: break-all
}

.detail-about-sign li:last-child p {
	text-align: left;
	word-break: unset
}

.package-detail-body {
	max-height: 300px;
	overflow: auto
}

.package-detail-wrap {
	padding: 0;
	list-style: none;
	margin: 0
}

.package-detail-wrap li {
	border-bottom: 1px solid #e5e5e5;
	padding: 7px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center
}

.package-detail-wrap li .sign-data {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.package-detail-wrap li strong {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto
}

.package-detail-wrap li p {
	padding-bottom: 0;
	font-size: 16px;
	text-align: right
}

.modal-footer.detail-about-sign-modal-footer {
	padding: .5rem 1rem
}

.modal-header.detail-about-sign-modal-header {
	padding: .5rem 1rem
}

.modal-header.detail-about-sign-modal-header i {
	margin-top: 5px
}

.modal-header.detail-about-sign-modal-header .modal-title {
	width: 100%
}

.who-need-sign-wrap .title {
	font-size: 16px;
	color: #2c4f7a;
	margin-bottom: 10px;
	font-weight: 600;
	text-transform: uppercase
}

.who-need-sign {
	margin-bottom: 10px
}

.right-body-content .who-need-sign-wrap h3.page_title {
	margin: 10px 0 30px 0
}

.theme_label {
	display: block;
	font-weight: 600;
	color: #2c4f7a
}

.role_name {
	overflow: hidden;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	white-space: nowrap
}

th.action-thead {
	min-width: 146px
}

th.status-thead {
	min-width: 90px
}

th.thead-minwidth-90 {
	min-width: 90px
}

.th-minwidth-110 {
	min-width: 110px
}

.share-template-modalBody .input-group .input-group-prepend .input-group-text {
	font-size: 16px;
	color: #495057;
	background: #f1f1f1;
	border: 1px solid #dcdcdc;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: .375rem .75rem;
	height: auto
}

.active-log-head h5 {
	padding: .375rem 0;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057
}

.active-log-head h5 span {
	font-weight: 700
}

.active-log-head .form-control {
	padding: .375rem .55rem;
	margin-bottom: 10px;
	text-align: left
}

.active-log-head.row {
	margin: 0 -5px
}

.active-log-head [class*=col-] {
	padding-left: 5px;
	padding-right: 5px
}

.activeLog-head-btn {
	text-align: right
}

.activeLog-head-btn button {
	width: 47%
}

.activeLog-head-btn button.btn-secondary {
	width: auto;
	max-width: 42px;
	min-width: auto
}

.activeLog-head-btn .clear-data {
	margin-right: 10px;
	margin-left: 0
}

.activeLog-head-btn a.theme_btn.ml_2 {
	margin-left: 10px
}

.switch-template-modalBody {
	max-height: 400px;
	overflow: auto
}

.role-category {
	background: #fff;
	position: relative;
	margin: 5px 0;
	border: 1px solid #ced4da;
	padding-left: 0
}

.role-category p {
	padding: 0 20px 0 10px;
	margin-bottom: 10px
}

.custom-switch [type=checkbox]:checked,
.custom-switch [type=checkbox]:not(:checked) {
	position: absolute;
	opacity: 0;
	pointer-events: none
}

.custom-switch label {
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	width: 100%;
	padding: 5px 10px;
	cursor: pointer;
	font-weight: 600
}

.custom-switch label:before {
	position: absolute;
	top: 9px;
	left: auto;
	right: 10px;
	display: block;
	width: 2rem;
	height: 1rem;
	pointer-events: none;
	content: "";
	background-color: #fff;
	border: #adb5bd solid 1px;
	border-radius: 50px
}

.custom-switch label:before {
	-webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
	-o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

.custom-switch label:after {
	display: block;
	width: 1rem;
	height: 1rem;
	content: "";
	background: no-repeat 50%/50% 50%
}

.custom-switch label:after {
	position: absolute;
	top: 11px;
	left: auto;
	right: 28px;
	width: calc(1rem - 4px);
	height: calc(1rem - 4px);
	background-color: #adb5bd;
	border-radius: .5rem;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
	-webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
	-o-transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
	transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out
}

.custom-switch input:checked+label:before {
	color: #fff;
	border-color: #2c4f7b;
	background-color: #2c4f7b
}

.custom-switch input:checked+label:after {
	background-color: #fff;
	-webkit-transform: translateX(17px);
	-ms-transform: translateX(17px);
	    transform: translateX(17px)
}

.create-template-anchor {
	display: block;
	margin-bottom: 5px
}

.react-tel-input input[type=tel],
.react-tel-input input[type=text] {
	width: 100%!important
}

.react-phone-number-input__country--native {
	margin-right: 0!important;
	font-size: 16px;
	color: #495057;
	background: #f1f1f1;
	border: 1px solid #dcdcdc;
	padding: .375rem .75rem;
	height: auto;
	border-right: 0;
	border-radius: .25rem 0 0 .25rem
}

.react-phone-number-input__input {
	padding: .375rem .5rem!important;
	background: #fff;
	border: 1px solid #dcdcdc!important;
	-webkit-box-shadow: none;
	        box-shadow: none;
	outline: 0!important;
	height: 40px!important;
	font-size: 13px!important;
	font-weight: 400;
	color: #717171;
	letter-spacing: .3px;
	line-height: normal;
	border-radius: 0 .25rem .25rem 0!important
}

.react-phone-number-input__icon {
	border: none!important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.who-need-sign-wrap-inner .row {
	margin-left: -5px;
	margin-right: -5px
}

.who-need-sign-wrap-inner .row [class*=col-] {
	padding-left: 5px;
	padding-right: 5px
}

#back-top-button {
	display: inline-block;
	background-color: #1f73b7;
	width: 50px;
	height: 50px;
	text-align: center;
	border-radius: 50%;
	position: fixed;
	bottom: 80px;
	right: 20px;
	-webkit-transition: background-color .3s, opacity .5s, visibility .5s;
	-o-transition: background-color .3s, opacity .5s, visibility .5s;
	transition: background-color .3s, opacity .5s, visibility .5s;
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
	font-size: 30px;
	color: #fff;
	line-height: 50px;
	-webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .5);
	        box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .5);
	cursor: pointer
}

#back-top-button:hover {
	background-color: #5da7e3
}

#back-top-button.show {
	opacity: 1;
	visibility: visible
}

.download-report-btnWrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end
}

.download-report-btnWrap>div {
	margin-right: 10px
}

.download-report-btnWrap .btn-primary a {
	color: #fff
}

.download-report-btnWrap .btn-primary a:active,
.download-report-btnWrap .btn-primary a:focus,
.download-report-btnWrap .btn-primary a:hover {
	color: #fff
}

.download-report-btnWrap .btn-primary i {
	display: inline-block;
	padding: 3px 2px;
	font-size: 13px;
	font-weight: 400;
	color: #007bff;
	border-radius: 2px;
	width: 20px;
	height: 20px;
	outline: 0;
	background: #fff
}

.download-report-btn a {
	color: #fff
}

.download-report-btn a:active,
.download-report-btn a:focus,
.download-report-btn a:hover {
	color: #fff
}

.download-report-btn i {
	display: inline-block;
	padding: 3px 2px;
	font-size: 13px;
	font-weight: 400;
	color: #007bff;
	border-radius: 2px;
	width: 20px;
	height: 20px;
	outline: 0;
	background: #fff
}

.two-btnWrap .btn-primary {
	margin-right: 10px
}

.download-subscribe-report {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end
}

.download-subscribe-report .search-container {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	margin-right: 10px
}

.download-subscribe-report .search-container .input-group.searchbar-group {
	float: none;
	max-width: initial
}

.search-with-button {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end
}

.search-with-button .search-container {
	margin-right: 10px;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 45%;
	        flex: 0 0 45%;
	max-width: 45%
}

.flex-parent.justify_content {
	-webkit-box-pack: unset;
	    -ms-flex-pack: unset;
	        justify-content: unset
}

.flex-parent.justify_content .searchbar-group {
	margin-left: auto;
	margin-right: 10px
}

.flex-parent.justify_content .download-report-btnWrap>div {
	margin: 0
}

.active_inactive_wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.active_inactive_wrap label {
	margin-right: 20px
}

.transaction-topbar {
	-webkit-box-pack: unset;
	    -ms-flex-pack: unset;
	        justify-content: unset
}

.transaction-topbar .react-bootstrap-daterangepicker-container {
	margin-left: auto;
	margin-right: 10px
}

.text_ellips {
	max-width: 170px;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block
}

.btn-mrg-right {
	margin-left: 10px
}

.addjust-grid-inResponsive .download-report-btnWrap .btn-info {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto
}

.addjust-grid-inResponsive .input-group.searchbar-group.search-input-group {
	max-width: auto
}

.disableDocument td {
	position: relative
}

.disableDocument td:nth-last-of-type(2):before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255, 255, 255, .73);
	z-index: 7;
	cursor: no-drop
}

.download-subscribe-report.mr .react-bootstrap-daterangepicker-container {
	margin-right: 10px
}

.featureSearch-with-btn {
	text-align: right;
	margin-top: 15px;
	padding-right: 15px
}

.create-feature-inputgroup.input-group .input-group-prepend .input-group-text {
	font-size: 16px;
	color: #495057;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	display: block;
	text-align: center;
	padding: .375rem .75rem;
	height: auto
}

.decline-document-header i {
	margin-top: 5px
}

.GDPR-content-wrapper p {
	padding-bottom: 0
}

tbody tr td .text-truncate {
	margin-bottom: 0;
	padding-bottom: 0;
}

.__react_component_tooltip {
	max-width: 470px;
}

.__react_component_tooltip span {
	white-space: normal;
}

.dashboard-header .navbar-nav .nav-item .dropdown {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.dashboard-header .navbar-nav .nav-item .dropdown span {
	margin-left: 10px;
	margin-right: 10px;
	font-family: 'Roboto', sans-serif !important;
	font-size: 13px;
	color: #000;
	font-weight: 500;
	text-align: left;
	text-transform: capitalize;
	letter-spacing: normal;
}

.dashboard-header .navbar-nav .nav-item .dropdown i {
	color: #000;
	font-size: 13px;
}
.editor_layout_wrap{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.editor_layout_wrap .elementsContainer{
	overflow-y: auto;
    flex-shrink: 0;
    width: 190px;
    padding: 10px 15px 20px 15px;
    max-height: calc(100vh - 60px);
    min-height: calc(100vh - 60px);
    background: rgba(226, 226, 226, 0.31);
}
.editor_layout_wrap .elementsContainer h4{
	font-size: 16px;
    font-weight: 600;
	margin-bottom: 10px;
	margin-top: 10px;
}
.editor_layout_wrap .elementsContainer .dragable_elements {
    display: block;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
	cursor: pointer;
	transition: all 0.3s;
}
.editor_layout_wrap .elementsContainer .dragable_elements:hover{
	background: #e2e2e2;
    padding-left: 10px;
}
#reapeaterimg{
	display: none;
}
.editor_layout_wrap .elementsBody{
	overflow-y: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 10px 15px 0 15px;
    max-height: calc(100vh - 60px);
    min-height: calc(100vh - 60px);
}
.editor_layout_wrap .elementsRight_content{
	width: 230px;
	overflow-y: auto;
    -ms-flex-negative: 0;
	flex-shrink: 0;
	max-height: calc(100vh - 60px);
	min-height: calc(100vh - 60px);
	background: rgba(226, 226, 226, 0.31);
}
.editor_layout_wrap .elementsRight_content h3{
	font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid #e2e2e2;
}
.selected_document{
	text-align: center;
    margin-top: 20px;
	margin-bottom: 15px;
	display: flex;
    justify-content: center;
}
.selected_document i{
	cursor: pointer;
	line-height: unset;
}
.selected_document span{
	padding: 0 12px;
	width: 182px;
    display: inline-block;
}
.selected_document_screenshot{
	padding: 0 15px;
}
.selected_document_screenshot .img_wrap{
	border: 1px solid #d3d3d3;
	height: 150px;
}
.selected_document_screenshot .img_wrap img{
	height: 100%;
	object-fit: cover;
}
.selected_document_wrap{
	border-top: 1px solid #e2e2e2;
    margin-top: 25px;
}
.selected_document_wrap.first{
	border-top: none;
    margin-top: 0;
}
.elementsContainer_custom_head{
	justify-content: space-between;
}
.elementsContainer_custom_head .editor_toolbar{
	margin: 0 auto;
	display: flex;
}
.elementsContainer_custom_head .editor_toolbar .arrows{
	display: flex;
	align-items: center;
	padding: 0 10px;
}
.elementsContainer_custom_head .editor_toolbar svg{
	height: 1em;
    width: 1em;
    margin: 0;
    font-size: 28px;
}
.elementsContainer_custom_head .editor_toolbar .forward{
	margin-left: 5px;
}
.elementsContainer_custom_head .editor_toolbar .forward svg{
	transform: scaleX(-1);
}

@media(max-width:1366px) {
	.package-widget {
		padding: 10px 7px
	}
	.panel-body .row .col {
		padding: 0 7px
	}
	.package-widget .right .heading {
		font-size: 13px
	}
	.package-widget .right .detial {
		font-size: 16px
	}
}

@media(max-width:1199px) {
	.panel-body.col-adjuctOnresponsive .row .col {
		max-width: 33.33333%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 33.33333%;
		        flex: 0 0 33.33333%
	}
	.addFile_for_template {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap
	}
	.addFile_for_template #selectedFileNames {
		width: 100%;
		margin-top: 10px
	}
	.new-feature input {
		width: calc(100% - 30px)!important
	}
	.role-category-parent .row {
		margin: 0 5px
	}
	.role-category-parent [class*=col-] {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 33.33333%;
		        flex: 0 0 33.33333%;
		max-width: 33.33333%;
		padding: 0 5px;
		margin-bottom: 10px
	}
	.role-category-parent .custom-switch label {
		font-size: 15px
	}
	.role-category-parent .role-category p {
		padding: 0 15px 0 7px;
		font-size: 13px;
		margin-bottom: 0
	}
	.role-category-parent .role-category {
		height: 100%
	}
}

@media(max-width:1090px) {
	.flex-parent.direction-change {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap
	}
	.right-body-content .flex-parent.contact_inquiries_head h3.page_title {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto
	}
	.flex-parent .search-input-group {
		max-width: 50%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 50%;
		        flex: 0 0 50%
	}
	.flex-parent.justify_content .searchbar-group {
		margin-right: 0
	}
	.two-btnWrap {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
		text-align: right;
		margin-top: 10px
	}
	.subscribe-page-head [class*=col-] {
		max-width: 100%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%
	}
	.download-subscribe-report {
		margin-bottom: 10px
	}
	.active-log-head.addjust-grid-inResponsive [class*=col-] {
		max-width: 50%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 50%;
		        flex: 0 0 50%
	}
	.active-log-head.addjust-grid-inResponsive [class*=col-]:last-of-type {
		max-width: 100%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%
	}
	.active-log-head.addjust-grid-inResponsive {
		margin-bottom: 10px
	}
	.active-log-head.addjust-grid-inResponsive button {
		width: auto
	}
	.addjust-grid-inResponsive .download-report-btnWrap .btn-info {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto
	}
}

@media(max-width:991px) {
	.package-detail-info-inner {
		margin-bottom: 30px
	}
	.paypal-payment {
		padding-top: 20px;
		padding-bottom: 20px;
		text-align: center
	}
	.paypal-payment span {
		display: block;
		margin: 0 0 20px;
		text-align: center;
		font-size: 20px;
		color: #000;
		font-weight: 600;
		text-transform: uppercase
	}
	.stripe-paypal span {
		display: none
	}
	.stripe-paypal {
		margin-top: 10px
	}
	.create-api-form .title {
		font-size: 16px
	}
	.table-cover .table th {
		font-size: 14px
	}
	.activeLog-head-btn {
		margin-bottom: 15px
	}
	.right-body-content .flex-parent.direction-change {
		-ms-flex-flow: wrap;
		    flex-flow: wrap
	}
	.right-body-content .flex-parent.direction-change h3.page_title {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 50%;
		        flex: 0 0 50%
	}
	.package-detail-info-inner .form-group {
		font-size: 14px;
		padding: 7px 10px
	}
	.package-detail-info-inner .form-group span {
		font-size: 14px
	}
	.right-body-content h3.page_title {
		font-size: 18px
	}
	.activeLog-head-btn {
		text-align: right
	}
	.activeLog-head-btn .btn {
		width: auto;
		min-width: 80px;
		font-size: 14px
	}
	.activeLog-head-btn .btn.download-report-btn {
		width: auto;
		min-width: auto
	}
	.search-container select#selectStatus {
		height: auto
	}
	.search-container button.btn.btn-info.margin-right-10.col-md-5 {
		width: auto;
		min-width: 80px;
		font-size: 14px
	}
	.search-container button.btn.btn-success.col-md-5 {
		width: auto;
		min-width: 80px;
		font-size: 14px;
		text-align: center
	}
	.search-container button.btn.btn-info.col-md-5 {
		width: auto;
		min-width: 80px;
		font-size: 14px;
		text-align: center
	}
	.search-container .btn-secondary {
		font-size: 14px
	}
	.text-right-sm {
		text-align: right
	}
	.flex-parent.direction-change .btn {
		font-size: 14px
	}
	.search-with-button .search-container {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto;
		max-width: inherit
	}
	.active-log-head.addjust-grid-inResponsive .input-group>.input-group-append>.btn {
		font-size: 14px
	}
}

@media(max-width:767px) {
	.profile-image {
		margin: 0 auto;
		max-width: 150px
	}
	.my-profile-form h3.page_title {
		margin: 10px 0 20px 0;
		font-size: 20px;
		font-weight: 600;
		border-bottom: 1px solid #eee;
		padding-bottom: 5px
	}
	.eSign-right-panel div#signature-pad canvas {
		width: 250px
	}
	.eSign-right-panel div#type-it-in input {
		font-size: 20px;
		height: 70px;
		margin-top: 10px
	}
	.eSign-right-panel {
		padding-bottom: 10px
	}
	.searchbar-group {
		max-width: 100%!important;
		float: none;
		margin-top: 10px
	}
	.flex-parent.direction-change .search-input-group {
		margin-bottom: 15px
	}
	.flex-parent.justify_content .searchbar-group {
		margin-bottom: 0
	}
	.role-category-parent [class*=col-] {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 50%;
		        flex: 0 0 50%;
		max-width: 50%
	}
	#back-top-button {
		height: 35px;
		width: 35px;
		line-height: 35px;
		font-size: 20px
	}
	.paypal-payment {
		padding-top: 10px;
		padding-bottom: 10px
	}
	.paypal-payment span {
		margin: 0 0 10px
	}
	.dash-logo.navbar-brand {
		padding-left: 0;
		margin-right: 0;
	}
	.download-subscribe-report .searchbar-group {
		margin-top: 0
	}
	.contact_inquiries_head {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	.contact_inquiries_head .download-report-btn {
		margin-top: 10px;
		margin-right: 0;
		-webkit-box-ordinal-group: 4;
		    -ms-flex-order: 3;
		        order: 3
	}
	.contact_inquiries_head .search-container {
		-webkit-box-flex: 1;
		    -ms-flex: 1 1 auto;
		        flex: 1 1 auto
	}
	.active-log-head.addjust-grid-inResponsive [class*=col-] {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
		max-width: 100%
	}
	.active-log-head.addjust-grid-inResponsive [class*=col-] .searchbar-group {
		margin-top: 0
	}
	.active-log-head.addjust-grid-inResponsive [class*=col-] .activeLog-head-btn {
		margin-bottom: 0;
		margin-top: 10px
	}
}

@media(max-width:575px) {
	.panel-body.col-adjuctOnresponsive .row .col {
		max-width: 50%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 50%;
		        flex: 0 0 50%
	}
	.panel .panel-heading {
		padding: 5px 10px;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center
	}
	.panel .panel-heading h3 {
		text-align: center
	}
	.panel .panel-heading a {
		margin: 10px 0
	}
	.eSign-right-panel #upload-image p:first-child {
		margin-bottom: 10px;
		padding-bottom: 0;
		font-size: 13px
	}
	.eSign-right-panel #upload-image {
		text-align: left;
		padding: 0 20px
	}
	.share-template-top-btn [class*=col-] {
		max-width: 50%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 50%;
		        flex: 0 0 50%
	}
	.share-template-top-btn [class*=col-] button.btn.theme_btn.add {
		font-size: 14px
	}
	.who-need-sign-wrap .title {
		font-size: 14px
	}
	.who-need-sign-wrap-inner {
		overflow: auto
	}
	.who-need-sign-wrap-inner .row {
		width: 540px
	}
	.flex-parent {
		display: block
	}
	.download-subscribe-report .search-container {
		-webkit-box-flex: 1;
		    -ms-flex: 1 1 100%;
		        flex: 1 1 100%;
		margin-left: 0;
		width: 100%
	}
	.contact_inquiries_head {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	.add-page-bottom-btns .col-md-6.col-sm-6.last-button {
		text-align: left!important;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 50%;
		        flex: 0 0 50%;
		margin-bottom: 0
	}
	.add-page-bottom-btns .col-md-6.col-sm-6.last-button.text-right {
		text-align: right!important
	}
	.download-subscribe-report.mr {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap
	}
	.download-subscribe-report.mr .react-bootstrap-daterangepicker-container {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
		max-width: 100%;
		margin: 0
	}
	.download-subscribe-report.mr .search-container {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
		margin-left: 0;
		width: 100%;
		margin-right: 0;
		margin-bottom: 10px
	}
	.decline-document-header .modal-title {
		font-size: 16px
	}
	.decline-document-body h4 {
		font-size: 18px
	}
	#back-top-button {
		bottom: 135px
	}
}

@media(max-width:480px) {
	.panel-body.col-adjuctOnresponsive .row .col {
		max-width: 1000%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%
	}
	.panel-body .row .col {
		max-width: 1000%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%
	}
	.eSign-right-panel div#type-it-in input {
		font-size: 18px;
		height: 50px;
		margin-top: 10px
	}
	.eSign-right-panel div#type-it-in .dropdown.allFontFamiliesList {
		margin-bottom: 10px
	}
	.users-btn-wrap .btn {
		font-size: 14px;
		padding: 5px 10px
	}
	.users-btn-wrap .theme-btn {
		margin: 0 5px
	}
	.flex-parent .users-btn-wrap .theme-btn {
		margin: 0
	}
	.role-category-parent [class*=col-] {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 0;
		padding: 0 15px
	}
	.role-category-parent .role-category {
		height: auto
	}
	.role-category-parent .role-category p {
		margin-bottom: 10px
	}
	.activeLog-head-btn button {
		min-width: 60px
	}
	.activeLog-head-btn .btn {
		padding: .375rem .55rem
	}
	.flex-parent.direction-change .btn {
		padding: .375rem .55rem
	}
}
.btn-checkout {
	background: #44b319;
	border: 2px solid #44b319;
	color: #fff;
	padding: .375rem .75rem;
	outline: 0!important
}
.btn.btn-checkout {
	background: #44b319;
	border: 2px solid #44b319;
	color: #fff;
	padding: .375rem .75rem;
	outline: 0!important
}

.btn-checkout:focus,
.btn-checkout:hover {
	background: 0 0;
	color: #000
}